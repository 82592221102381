import { Box, Button, FormControl, FormControlLabel, FormLabel, Grid, MenuItem, Radio, RadioGroup, TextField } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import RkdCard from "../../app/component/card/RkdCard";
import { useStore } from "../../app/stores/store";
import {
  avgTestPressure,
  samePort,
  steamEnthAvgPFormula,
  brineEnthAvgPFormula,
  tmfAvgPFormula,
  tmfAvgPNgcCorFormula,
  steamFractionAvgPFormula,
  steamFractionAvgPNcgFormula,
  steamFractionAvgPChoiceFormula,
  steamEnthSteamPFormula,
  brineEnthSteamPFormula,
  calcBrineSteamPFormula,
  calcSteamBrinePNcgCorFormula,
  steamFracBrinePFormula,
  tmfSteamPFormula,
  tmfSteamPNcgCorFormula,
  steamFracSteamPFormula,
  steamFracSteamPNcgCorFormula,
  steamFractSteamPChoiceFormula,
  steamEnthBrinePFormula,
  brineEnthBrinePFormula,
  calcSteamBrinePFormula,
  steamFracBrinePNcgCorFormula,
  steamFracBrinePChoiceFormula,
  totEnthAvgPressFOrmula,
  totEnthAvgPressNcgCorFormula,
  totEnthDiffPressFormula,
  totEnthDiffPressNcgCorFormula,
  steamEnthProdP,
  brineEnthProdP,
  calcSteamProdP,
  totalAvgFlowFormula,
  avgEnthalpyFormula,
  calcBrineProdPFormula,
  tmfProdPFormula,
  steamFracProdP,
} from "./helper/tftFormula";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Typography from "@mui/material/Typography";
import { IObjectOptions } from "../../app/models/object";
import { IPortLocationOption } from "../../app/models/portLocation";
import { useNavigate, useParams } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import TFTProcessTab from "./TFTProcessTab";
import { TFTPageProcessDisabledField } from "../../app/config/utils";
import { ITft } from "../../app/models/tft";

function TFTPageProcess() {
  const navigate = useNavigate();

  const { date, id } = useParams();

  const { tftSteamStore, objectStore, portLocationStore } = useStore();

  const { tft, tftAverages } = tftSteamStore;
  console.log("🚀 ~ TFTPageProcess ~ tftSteamStore:", tftSteamStore);

  const [objectType, setObjectType] = useState<Array<IObjectOptions>>([]);
  const [portLocation, setPortLocation] = useState<Array<IPortLocationOption>>([]);

  useEffect(() => {
    if (id && date) {
      tftSteamStore.detailTft(date, id);
    } else {
      tftSteamStore.resetTft();
    }
  }, [id, date, tftSteamStore]);

  useEffect(() => {
    objectStore.getObjectOptions("").then((res) => {
      setObjectType(res);
    });
  }, [objectStore]);

  useEffect(() => {
    portLocationStore.getPortLocationOptions("").then((res) => {
      setPortLocation(res);
    });
  }, [portLocationStore]);

  useEffect(() => {
    tftSteamStore.setAvgTestPressure(
      avgTestPressure(tft.samePort, tftSteamStore.tft.avgBrinePressure, tftSteamStore.tft.avgSteamPressure, tftSteamStore.tft.pressDiffFlag)
    );
  }, [tft.samePort, tftSteamStore, tftSteamStore.tft.avgBrinePressure, tftSteamStore.tft.avgSteamPressure, tftSteamStore.tft.pressDiffFlag]);

  useEffect(() => {
    tftSteamStore.setTmfAvgP(tmfAvgPFormula(tft.averageTestPressure, tftAverages.steam.avgSteamFlowRate, tftAverages.brine.avgBrineFlowRate));
  }, [tft.averageTestPressure, tftAverages.brine.avgBrineFlowRate, tftAverages.steam.avgSteamFlowRate, tftSteamStore]);

  useEffect(() => {
    tftSteamStore.setTmfAvgPNcgCor(
      tmfAvgPNgcCorFormula(tft.averageTestPressure, tftSteamStore.correctionSteamFlowRate, tftAverages.brine.avgBrineFlowRate)
    );
  }, [tft.averageTestPressure, tftAverages.brine.avgBrineFlowRate, tftSteamStore]);

  useEffect(() => {
    tftSteamStore.setSteamFractionAvgP(steamFractionAvgPFormula(tft.averageTestPressure, tftAverages.steam.avgSteamFlowRate, tft.tmfAvgP));
  }, [tft.averageTestPressure, tft.tmfAvgP, tftAverages.steam.avgSteamFlowRate, tftSteamStore]);

  useEffect(() => {
    tftSteamStore.setCalcBrineSteamP(
      calcBrineSteamPFormula(
        tft.samePort,
        tft.pressDiffFlag,
        tftAverages.brine.avgBrineFlowRate,
        tftAverages.steam.avgSteamFlowRate,
        tft.steamEnthSteamP,
        tft.brineEnthBrineP,
        tft.steamEnthBrineP,
        tft.brineEnthSteamP
      )
    );
  }, [
    tft.brineEnthBrineP,
    tft.brineEnthSteamP,
    tft.pressDiffFlag,
    tft.samePort,
    tft.steamEnthBrineP,
    tft.steamEnthSteamP,
    tftAverages.brine.avgBrineFlowRate,
    tftAverages.steam.avgSteamFlowRate,
    tftSteamStore,
  ]);

  useEffect(() => {
    tftSteamStore.setCalcSteamBrineP(
      calcSteamBrinePFormula(
        tft.samePort,
        tft.pressDiffFlag,
        tft.steamEnthSteamP,
        tft.steamEnthBrineP,
        tftAverages.steam.avgSteamFlowRate,
        tft.brineEnthBrineP,
        tftAverages.brine.avgBrineFlowRate,
        tft.brineEnthSteamP
      )
    );
    tftSteamStore.setCalcBrineSteamPNcgCor(
      calcBrineSteamPFormula(
        tft.samePort,
        tft.pressDiffFlag,
        tftAverages.brine.avgBrineFlowRate,
        tftAverages.steam.avgSteamFlowRate,
        tft.steamEnthSteamP,
        tft.brineEnthBrineP,
        tft.steamEnthBrineP,
        tft.brineEnthSteamP
      )
    );
    tftSteamStore.setTotalEnthDiffPress(
      totEnthDiffPressFormula(
        tft.samePort,
        tft.pressDiffFlag,
        tft.steamEnthSteamP,
        tftAverages.steam.avgSteamFlowRate,
        tft.brineEnthBrineP,
        tftAverages.brine.avgBrineFlowRate,
        tft.brineEnthSteamP,
        tft.steamEnthBrineP
      )
    );
  }, [
    tft.brineEnthBrineP,
    tft.brineEnthSteamP,
    tft.pressDiffFlag,
    tft.samePort,
    tft.steamEnthBrineP,
    tft.steamEnthSteamP,
    tftAverages.brine.avgBrineFlowRate,
    tftAverages.steam.avgSteamFlowRate,
    tftSteamStore,
  ]);

  useEffect(() => {
    tftSteamStore.setTmfSteamP(tmfSteamPFormula(tftAverages.steam.avgSteamFlowRate, tft.calcBrineSteamP));
  }, [tft.calcBrineSteamP, tftAverages.steam.avgSteamFlowRate, tftSteamStore]);

  useEffect(() => {
    tftSteamStore.setSteamFractionSteamP(steamFracSteamPFormula(tft.tmfSteamP, tftAverages.steam.avgSteamFlowRate));
  }, [tft.tmfSteamP, tftAverages.steam.avgSteamFlowRate, tftSteamStore]);

  useEffect(() => {
    tftSteamStore.setSteamFractionBrineP(steamFracBrinePFormula(tft.tmfSteamP, tft.calcSteamBrineP));
    tftSteamStore.setSteamFractionBrinePNcgCor(steamFracBrinePNcgCorFormula(tft.tmfSteamP, tft.calcSteamBrineP));
    tftSteamStore.setSteamFractionBrinePChoice(steamFracBrinePChoiceFormula(tft.tmfSteamP, tft.calcSteamBrineP));
  }, [tft.calcSteamBrineP, tft.tmfSteamP, tftSteamStore]);

  // useEffect(() => {
  //   // OTW
  //   if (!tftSteamStore || !tft || !tftAverages) return;
  // }, [
  //   tftSteamStore,
  //   tft,
  //   tft.avgSteamPressure,
  //   tftAverages.steam.avgSteamFlowRate,
  //   tft.ncgContent,
  //   tftAverages.brine.avgBrineFlowRate,
  //   tftSteamStore.correctionSteamFlowRate,
  //   tftAverages.brine.avgPressPsig,
  //   tft.baroPress,
  //   tft.steamEnthSteamP,
  //   tft.brineEnthBrineP,
  //   tft.steamEnthBrineP,
  //   tft.brineEnthSteamP,
  //   tft.tmfSteamP,
  //   tft.tmfSteamPNCGcorr,
  //   tft.calcBrineSteamP,
  //   tft.calcBrineSteamPNCGCorr,
  //   tft.tmfSteamP,
  //   tft.steamFractionSteamP,
  //   tft.steamFractionSteamPNCGCorr,
  //   tft.steamFractionSteamPChoice,
  //   tft.steamEnthBrineP,
  //   tft.brineEnthBrineP,
  //   tft.calcSteamBrineP,
  //   tft.calcSteamBrinePNCGcorr,
  //   tft.steamFractionBrineP,
  //   tft.steamFractionBrinePNCGCorr,
  //   tft.steamFractionBrinePChoice,
  //   tft.totalEnthDiffPressures,
  //   tft.totalEnthAvgPressure,
  //   tft.totalEnthDiffPressuresNCGcorr,
  //   tft.totalEnthAvgPressureNCGcorr,
  //   tft.productionSepPressure,
  //   tft.steamEnthProdP,
  //   tft.brineEnthProdP,
  //   tft.totalEnthDiffPressures,
  //   tft.totalEnthAvgPressure,
  //   tft.totalEnthDiffPressuresNCGcorr,
  //   tft.totalEnthAvgPressureNCGcorr,
  //   tft.calcSteamProdP,
  //   tft.calcBrineProdP,
  //   tft.tmfProductionP,
  // ]);

  useEffect(() => {
    tftSteamStore.setSteamFlowSteamPChoice(tftSteamStore.correctionSteamFlowRate);
  }, [tftSteamStore, tftSteamStore.correctionSteamFlowRate]);

  return (
    <RkdCard title="Tracer Flow Test" subtitle="Process">
      <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
        <Accordion sx={{ mb: 1, mt: 1 }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            sx={{
              backgroundColor: "#43c55f",
            }}
          >
            <Typography sx={{ color: "white" }}>TFT Header 1</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 5 }} sx={{ mt: 2, pl: 2 }}>
              <Grid container item xs={6} spacing={2}>
                <TextField
                  select
                  label="Well"
                  value={tftSteamStore.tft.objectId}
                  onChange={(e) => tftSteamStore.setWell(e.target.value)}
                  fullWidth
                  required
                  name="well"
                  sx={{ mb: 1 }}
                >
                  {objectType.map((item, idx) => (
                    <MenuItem key={idx} value={item.value}>
                      {item.text}
                    </MenuItem>
                  ))}
                </TextField>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    onChange={(e) => tftSteamStore.setTftDate(e)}
                    value={tftSteamStore.tft.tftDate}
                    label="Date"
                    renderInput={(props) => <TextField label="Date" fullWidth {...props} sx={{ mb: 1 }} />}
                  />
                </LocalizationProvider>
                <TextField
                  name="wht"
                  label="WHT (C)"
                  variant="outlined"
                  fullWidth
                  value={tftSteamStore.tft.wht}
                  onChange={(e) => tftSteamStore.setWht(Number(e.target.value))}
                  sx={{ mb: 1 }}
                />
                <TextField
                  name="whp"
                  label="WHP (Barg)"
                  variant="outlined"
                  fullWidth
                  value={tftSteamStore.tft.whp}
                  onChange={(e) => tftSteamStore.setwhp(Number(e.target.value))}
                  sx={{ mb: 1 }}
                />
                <TextField
                  name="controlValve"
                  label="Control Valve (%)"
                  variant="outlined"
                  fullWidth
                  value={tftSteamStore.tft.controlValve}
                  onChange={(e) => tftSteamStore.setControlValve(Number(e.target.value))}
                  sx={{ mb: 1 }}
                />
                <TextField
                  name="pumpStroke"
                  label="Pump Stroke (%)"
                  variant="outlined"
                  fullWidth
                  value={tftSteamStore.tft.pumpStroke}
                  onChange={(e) => tftSteamStore.setPumpStroke(Number(e.target.value))}
                  sx={{ mb: 1 }}
                />
                <TextField
                  select
                  label="Steam Port Location"
                  value={tftSteamStore.tft.steamPortLoccationId}
                  onChange={(e) => {
                    tftSteamStore.setSteamPortLoc(e.target.value);
                    tftSteamStore.setSamePort(samePort(e.target.value, tftSteamStore.tft.brinePortLocationId));
                  }}
                  fullWidth
                  required
                  name="steamPortLoc"
                  sx={{ mb: 1 }}
                >
                  {portLocation.map((item, idx) => (
                    <MenuItem key={idx} value={item.value}>
                      {item.text}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  select
                  label="Brine Port Location"
                  value={tftSteamStore.tft.brinePortLocationId}
                  onChange={(e) => {
                    tftSteamStore.setBrinePortLocation(e.target.value);
                    tftSteamStore.setSamePort(samePort(tftSteamStore.tft.steamPortLoccationId, e.target.value));
                  }}
                  fullWidth
                  required
                  name="brinePortLoc"
                  sx={{ mb: 1 }}
                >
                  {portLocation.map((item, idx) => (
                    <MenuItem key={idx} value={item.value}>
                      {item.text}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  name="mfc"
                  label="MFC In/Out"
                  variant="outlined"
                  fullWidth
                  value={tftSteamStore.tft.mfc}
                  onChange={(e) => tftSteamStore.setMfc(e.target.value)}
                  sx={{ mb: 1 }}
                />
                <TextField
                  name="brineTracerName"
                  label="Brine Tracer sss"
                  variant="outlined"
                  fullWidth
                  value={tftSteamStore.tft.brineTracerName}
                  onChange={(e) => tftSteamStore.setBrineTracerName(e.target.value)}
                  sx={{ mb: 1 }}
                />
              </Grid>
              <Grid container item xs={6} spacing={2}>
                <TextField
                  name="brineBaseline"
                  label={`Brine Baseline ${tft.brineTracerName}`}
                  variant="outlined"
                  fullWidth
                  value={tftSteamStore.tft.brineBaseline}
                  onChange={(e) => tftSteamStore.setBrineBaseline(e.target.value)}
                  sx={{ mb: 1 }}
                />
                <TextField
                  name="wt"
                  label="Wt %"
                  variant="outlined"
                  fullWidth
                  value={tftSteamStore.tft.wtPTSASolin}
                  onChange={(e) => tftSteamStore.setWTPTSASolin(Number(e.target.value))}
                  sx={{ mb: 1 }}
                />
                <TextField
                  name="steamTracerName"
                  label="Steam Tracer Name"
                  variant="outlined"
                  fullWidth
                  value={tftSteamStore.tft.steamTracerName}
                  onChange={(e) => tftSteamStore.setSteamTracerName(e.target.value)}
                  sx={{ mb: 1 }}
                />
                <TextField
                  type="number"
                  name="steamTracerMW"
                  label="Steam Tracer MW"
                  variant="outlined"
                  InputProps={{ inputProps: { min: 0, max: 1, step: "0.01" } }}
                  fullWidth
                  value={tftSteamStore.tft.steamTracerMW}
                  onChange={(e) => tftSteamStore.setSteamTracerMW(Number(e.target.value))}
                  sx={{ mb: 1 }}
                />
                <TextField
                  name="steamBaseline"
                  label={`Steam Baseline ${tft.steamTracerName}`}
                  variant="outlined"
                  fullWidth
                  value={tftSteamStore.tft.steamBaseline}
                  onChange={(e) => tftSteamStore.setSteamBaseline(e.target.value)}
                  sx={{ mb: 1 }}
                />
                <TextField
                  name="volSF6"
                  label="Vol % SF6"
                  variant="outlined"
                  fullWidth
                  value={tftSteamStore.tft.volSF6}
                  onChange={(e) => tftSteamStore.setVolSF6(Number(e.target.value))}
                  sx={{ mb: 1 }}
                />
                <TextField
                  name="baroPress"
                  label="Baro. Press (psia)"
                  variant="outlined"
                  fullWidth
                  value={tftSteamStore.tft.baroPress}
                  onChange={(e) => {
                    tftSteamStore.setBaroPress(Number(e.target.value));
                    tftSteamStore.setTotalEnthAvgPressNcgCor(
                      totEnthAvgPressNcgCorFormula(tft.samePort, tft.pressDiffFlag, tftAverages.brine.avgPressPsig, Number(e.target.value))
                    );
                    tftSteamStore.setSteamEnthProdP(steamEnthProdP(tft.productionSepPressure, Number(e.target.value)));
                    tftSteamStore.setBrineEnthProdP(brineEnthProdP(tft.productionSepPressure, Number(e.target.value)));
                    tftSteamStore.setTotalEnthAvgPress(
                      totEnthAvgPressFOrmula(tft.samePort, tft.pressDiffFlag, tftAverages.brine.avgPressPsig, Number(e.target.value))
                    );
                    tftSteamStore.setSteamEnthBrineP(
                      steamEnthBrinePFormula(tft.samePort, tft.pressDiffFlag, tftAverages.brine.avgPressPsig, Number(e.target.value))
                    );
                    tftSteamStore.setBrineEnthBrineP(
                      brineEnthBrinePFormula(tft.samePort, tft.pressDiffFlag, tftAverages.brine.avgPressPsig, Number(e.target.value))
                    );
                    tftSteamStore.setSteamEnthAvgP(steamEnthAvgPFormula(tft.averageTestPressure, Number(e.target.value)));
                    tftSteamStore.setBrineEnthAvgP(brineEnthAvgPFormula(tft.averageTestPressure, Number(e.target.value)));
                    tftSteamStore.setSteamEnthSteamP(
                      steamEnthSteamPFormula(tft.samePort, tft.pressDiffFlag, tft.avgSteamPressure, Number(e.target.value))
                    );
                    tftSteamStore.setBrineEnthSteamP(
                      brineEnthSteamPFormula(tft.samePort, tft.pressDiffFlag, tft.avgSteamPressure, Number(e.target.value))
                    );
                  }}
                  sx={{ mb: 1 }}
                />
                <TextField
                  type="number"
                  name="ncgContent"
                  label="NCG Content (wt %)"
                  variant="outlined"
                  InputProps={{ inputProps: { min: 0, max: 1, step: "0.01" } }}
                  fullWidth
                  value={tftSteamStore.tft.ncgContent}
                  onChange={(e) => {
                    tftSteamStore.setNcgContent(Number(e.target.value));
                    tftSteamStore.setSteamFractionSteamPChoice(
                      steamFractSteamPChoiceFormula(Number(e.target.value), tft.steamFractionSteamP, tft.steamFractionSteamPNCGCorr)
                    );
                    tftSteamStore.setAvgTotalFlow(
                      totalAvgFlowFormula(
                        Number(e.target.value),
                        tft.samePort,
                        tft.pressDiffFlag,
                        tft.tmfSteamP,
                        tft.tmfSteamPNCGcorr,
                        tft.tmfAvgP,
                        tft.tmfAvgPNCGcorr
                      )
                    );
                    tftSteamStore.setAvgEnthalpy(
                      avgEnthalpyFormula(
                        Number(e.target.value),
                        tft.samePort,
                        tft.pressDiffFlag,
                        tft.totalEnthDiffPressures,
                        tft.totalEnthAvgPressure,
                        tft.totalEnthDiffPressuresNCGcorr,
                        tft.totalEnthAvgPressureNCGcorr
                      )
                    );
                    tftSteamStore.setTotalEnthDiffPressNcgCor(
                      totEnthDiffPressNcgCorFormula(
                        Number(e.target.value),
                        tft.samePort,
                        tft.pressDiffFlag,
                        tft.steamEnthSteamP,
                        tftSteamStore.correctionSteamFlowRate,
                        tft.brineEnthBrineP,
                        tftAverages.brine.avgBrineFlowRate,
                        tft.brineEnthSteamP,
                        tft.steamEnthBrineP
                      )
                    );
                    tftSteamStore.setSteamFractionSteamPNcgCor(
                      steamFracSteamPNcgCorFormula(Number(e.target.value), tft.tmfSteamPNCGcorr, tftSteamStore.correctionSteamFlowRate)
                    );
                    tftSteamStore.setTmfSteamPNcgCor(
                      tmfSteamPNcgCorFormula(Number(e.target.value), tftSteamStore.correctionSteamFlowRate, tft.calcBrineSteamPNCGCorr)
                    );
                    tftSteamStore.setSteamFlowSteamPChoice(
                      Number(e.target.value) === 0 ? tftAverages.steam.avgSteamFlowRate : tftSteamStore.correctionSteamFlowRate
                    );
                    tftSteamStore.setSteamFractionAvgPNcgCor(
                      steamFractionAvgPNcgFormula(
                        Number(e.target.value),
                        tft.averageTestPressure,
                        tftSteamStore.correctionSteamFlowRate,
                        tft.tmfAvgPNCGcorr
                      )
                    );
                    tftSteamStore.setSteamFractionAvgPChoice(
                      steamFractionAvgPChoiceFormula(Number(e.target.value), tft.steamFractionAvgP, tft.steamFractionAvgPNCGCorr)
                    );
                    tftSteamStore.setCalcSteamBrinePNcgCor(
                      calcSteamBrinePNcgCorFormula(
                        Number(e.target.value),
                        tft.samePort,
                        tft.pressDiffFlag,
                        tft.steamEnthSteamP,
                        tft.steamEnthBrineP,
                        tftSteamStore.correctionSteamFlowRate,
                        tft.brineEnthBrineP,
                        tftAverages.brine.avgBrineFlowRate,
                        tft.brineEnthSteamP
                      )
                    );
                  }}
                  sx={{ mb: 1 }}
                />
                <TextField
                  name="productionSepPressure"
                  label="Production Separator Pressure"
                  variant="outlined"
                  fullWidth
                  value={tftSteamStore.tft.productionSepPressure}
                  onChange={(e) => {
                    tftSteamStore.setProductionSepPressure(Number(e.target.value));
                    tftSteamStore.setSteamEnthProdP(steamEnthProdP(Number(e.target.value), tftSteamStore.tft.baroPress));
                    tftSteamStore.setBrineEnthProdP(brineEnthProdP(Number(e.target.value), tftSteamStore.tft.baroPress));
                    tftSteamStore.setCalcSteamProdP(calcSteamProdP(Number(e.target.value), tftSteamStore.avgTotalFlow, tft.calcBrineProdP));
                    tftSteamStore.setCalcBrineProdP(
                      calcBrineProdPFormula(
                        Number(e.target.value),
                        tftSteamStore.avgTotalFlow,
                        tftSteamStore.avgEnthalpy,
                        tft.steamEnthProdP,
                        tft.brineEnthProdP
                      )
                    );
                    tftSteamStore.setTmfProdP(
                      tmfProdPFormula(
                        Number(e.target.value),
                        calcSteamProdP(Number(e.target.value), tftSteamStore.avgTotalFlow, tft.calcBrineProdP),
                        tft.calcBrineProdP
                      )
                    );
                    tftSteamStore.setSteamFractionProdP(
                      steamFracProdP(
                        Number(e.target.value),
                        calcSteamProdP(Number(e.target.value), tftSteamStore.avgTotalFlow, tft.calcBrineProdP),
                        tft.tmfProductionP
                      )
                    );
                  }}
                  sx={{ mb: 1 }}
                />
                <FormControl>
                  <FormLabel id="demo-row-radio-buttons-group-label">Report Analytical</FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    value={tftSteamStore.tft.reportAnalytical ? "yes" : "no"}
                    onChange={(e) => tftSteamStore.setReportAnalytical(e.target.value)}
                  >
                    <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                    <FormControlLabel value="no" control={<Radio />} label="No" />
                  </RadioGroup>
                </FormControl>
                <TextField
                  name="tmf"
                  label="TMF/H calculation Block"
                  variant="outlined"
                  fullWidth
                  value={tftSteamStore.tft.tmfHcalculationBlock}
                  onChange={(e) => tftSteamStore.setTmfCalculation(Number(e.target.value))}
                  sx={{ mb: 1 }}
                />
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
        <Accordion sx={{ mb: 2, border: "none", shadow: "none" }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            sx={{
              backgroundColor: "#43c55f",
            }}
          >
            <Typography sx={{ color: "white" }}>TFT Header 2</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 5 }} sx={{ mt: 2, pl: 2 }} alignItems="center">
              {TFTPageProcessDisabledField.map((item) => {
                let displayData = tftSteamStore.tft[item.name as keyof ITft] ? tftSteamStore.tft[item.name as keyof ITft].toString() : "";
                let finalData = displayData === "true" ? "Yes" : displayData === "false" ? "No" : displayData;

                const handleChangeData = (e: React.ChangeEvent<HTMLInputElement>) => {
                  switch (item.name) {
                    case "samePort":
                      tftSteamStore.setSamePort(!!e.target.value);
                      break;
                    case "pressDiffFlag":
                      tftSteamStore.setDiffFlag(!!e.target.value);
                      break;
                    case "avgSteamPressure":
                      tftSteamStore.setAvgSteamPressure(Number(e.target.value));
                      break;
                    case "avgBrinePressure":
                      tftSteamStore.setAvgBrinePressure(Number(e.target.value));
                      break;
                    case "averageTestPressure":
                      tftSteamStore.setAvgTestPressure(Number(e.target.value));
                      break;
                    case "steamEnthAvgP":
                      tftSteamStore.setSteamEnthAvgP(Number(e.target.value));
                      break;
                    case "brineEnthAvgP":
                      tftSteamStore.setBrineEnthAvgP(Number(e.target.value));
                      break;
                    case "steamFlowSteamPChoice":
                      tftSteamStore.setSteamFlowSteamPChoice(Number(e.target.value));
                      break;
                    case "tmfAvgP":
                      tftSteamStore.setTmfAvgP(Number(e.target.value));
                      break;
                    case "tmfAvgPNCGcorr":
                      tftSteamStore.setTmfAvgPNcgCor(Number(e.target.value));
                      break;
                    case "steamFractionAvgP":
                      tftSteamStore.setSteamFractionAvgP(Number(e.target.value));
                      break;
                    case "steamFractionAvgPNCGCorr":
                      tftSteamStore.setSteamFractionAvgPNcgCor(Number(e.target.value));
                      break;
                    case "steamFractionAvgPChoice":
                      tftSteamStore.setSteamFractionAvgPChoice(Number(e.target.value));
                      break;
                    case "steamEnthSteamP":
                      tftSteamStore.setSteamEnthSteamP(Number(e.target.value));
                      break;
                    case "brineEnthSteamP":
                      tftSteamStore.setBrineEnthSteamP(Number(e.target.value));
                      break;
                    case "calcBrineSteamP":
                      tftSteamStore.setCalcBrineSteamP(Number(e.target.value));
                      break;
                    case "calcBrineSteamPNcgCorr":
                      tftSteamStore.setCalcBrineSteamPNcgCor(Number(e.target.value));
                      break;
                    case "tmfSteamP":
                      tftSteamStore.setTmfSteamP(Number(e.target.value));
                      break;
                    case "tmfSteamPNcgCorr":
                      tftSteamStore.setTmfSteamPNcgCor(Number(e.target.value));
                      break;
                    case "steamFractionSteamP":
                      tftSteamStore.setSteamFractionSteamP(Number(e.target.value));
                      break;
                    case "steamEnthBrineP":
                      tftSteamStore.setSteamEnthBrineP(Number(e.target.value));
                      break;
                    case "brineEnthBrineP":
                      tftSteamStore.setBrineEnthBrineP(Number(e.target.value));
                      break;
                    case "calcSteamBrineP":
                      tftSteamStore.setCalcSteamBrineP(Number(e.target.value));
                      break;
                    case "calcSteamBrinePNcgCorr":
                      tftSteamStore.setCalcSteamBrinePNcgCor(Number(e.target.value));
                      break;
                    case "steamFractionBrineP":
                      tftSteamStore.setSteamFractionBrineP(Number(e.target.value));
                      break;
                    case "steamFractionBrinePNcgCorr":
                      tftSteamStore.setSteamFractionBrinePNcgCor(Number(e.target.value));
                      break;
                    case "steamFractionBrinePChoice":
                      tftSteamStore.setSteamFractionBrinePChoice(Number(e.target.value));
                      break;
                    case "totalEnthAvgPress":
                      tftSteamStore.setTotalEnthAvgPress(Number(e.target.value));
                      break;
                    case "totalEnthAvgPressNcgCorr":
                      tftSteamStore.setTotalEnthAvgPressNcgCor(Number(e.target.value));
                      break;
                    case "totalEnthDiffPress":
                      tftSteamStore.setTotalEnthDiffPress(Number(e.target.value));
                      break;
                    case "totalEnthDiffPressNcgCorr":
                      tftSteamStore.setTotalEnthDiffPressNcgCor(Number(e.target.value));
                      break;
                    case "steamEnthProdP":
                      tftSteamStore.setSteamEnthProdP(Number(e.target.value));
                      break;
                    case "brineEnthProdP":
                      tftSteamStore.setBrineEnthProdP(Number(e.target.value));
                      break;
                    case "calcSteamProdP":
                      tftSteamStore.setCalcSteamProdP(Number(e.target.value));
                      break;
                    case "calcBrineProdP":
                      tftSteamStore.setCalcBrineProdP(Number(e.target.value));
                      break;
                    case "tmfProductionP":
                      tftSteamStore.setTmfProdP(Number(e.target.value));
                      break;
                    // case "steamFractionProdP":
                    //   tftSteamStore.setSteamFractionProdP(Number(e.target.value));
                    //   break;
                    default:
                      tftSteamStore.setSteamFractionProdP(Number(e.target.value));
                      break;
                  }
                };

                return (
                  <Grid item xs={4} gap="12px">
                    <TextField
                      onChange={handleChangeData}
                      name={item.name}
                      InputLabelProps={{ shrink: true }}
                      disabled
                      label={item.label}
                      variant="outlined"
                      fullWidth
                      type="number"
                      value={finalData}
                      sx={{ mb: 1 }}
                    />
                  </Grid>
                );
              })}
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Box>
      <TFTProcessTab />
      <Box sx={{ display: "flex", justifyContent: "flex-start", gap: 1 }}>
        <Button
          variant="contained"
          color="error"
          onClick={() => {
            tftSteamStore.resetTft();
            navigate("/tracer-flow-test/");
          }}
        >
          Cancel
        </Button>
        <LoadingButton
          variant="contained"
          color="primary"
          type="submit"
          loading={tftSteamStore.loadingForm}
          onClick={() => tftSteamStore.draftTft(tftSteamStore.tft).then(() => navigate("/tracer-flow-test/"))}
        >
          Save as Draft
        </LoadingButton>
        <LoadingButton
          variant="contained"
          color="primary"
          type="submit"
          loading={tftSteamStore.loadingForm}
          onClick={() => tftSteamStore.createTft(tftSteamStore.tft).then(() => navigate("/tracer-flow-test/"))}
        >
          Save
        </LoadingButton>
      </Box>
    </RkdCard>
  );
}

export default observer(TFTPageProcess);
