import { action, makeObservable, observable, runInAction } from "mobx";
import {
  Decimation,
  DecimationSegmentCount,
  DecimationSegmentDetailsTable,
  DecimationSegmentTable,
  DecimationSelectedSegment,
  IDecimation,
  TChartStep3Payload,
} from "../models/decimation";
import DataGridStore from "./materialUI/dataGridStore";
import agent from "../api/agent";
import { IDeleteData } from "../models/account";

export default class DecimationStore extends DataGridStore {
  loadingDelete = false;
  loadingForm = false;
  loadingDecimationUploadTempalte = false;
  loadingGridDecimation = false;
  loadingChart = false;

  decimation = new Decimation();
  decimationSegmenCount = new DecimationSegmentCount();
  decimationSelectedSegment = Array<DecimationSelectedSegment>();
  mergedAndInterpolateDataFromStep2 = Array<any>();
  selectedDecimation = new Decimation();

  constructor() {
    super();
    makeObservable(this, {
      loadingDelete: observable,
      loadingChart: observable,
      selectedDecimation: observable,
      loadingDecimationUploadTempalte: observable,
      loadingForm: observable,
      loadingGridDecimation: observable,
    });
  }

  setDecimation = (decimation: IDecimation) => {
    try {
      this.decimation = new Decimation();
      this.decimation.wellId = decimation.wellId;
      this.decimation.surveyDate = new Date(decimation.surveyDate + "Z");
      this.decimation.surveyNo = decimation.surveyNo;
      this.decimation.currentStep = decimation.currentStep;
      this.decimation.table = decimation.table;
      this.decimation.selectedSegments = decimation.selectedSegments;
      this.decimation.decimationSegmentDetails = decimation.decimationSegmentDetails;
      this.decimation.table.forEach((row) => {
        row.time = new Date(row.time + "Z");
      });
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => (this.loadingForm = false));
    }
  };

  setDecimationSegmentTable = (table: Array<DecimationSegmentTable>) => {
    this.decimation.table = table;
  };

  setDecimationSegmentCount = (value: number) => {
    this.decimationSegmenCount.value = value;
  };

  setDecimationSegmentSelected = (values: Array<number>) => {
    this.decimation.selectedSegments = values;
  };

  setDecimationSegmentDetails = (values: Array<DecimationSegmentDetailsTable>) => {
    this.decimation.decimationSegmentDetails = values;
  };

  setDecimationSelectedSegment = (values: any) => {
    this.decimationSelectedSegment = values;
  };

  setMergedAndInterpolateDataFromStep2 = (values: any) => {
    this.mergedAndInterpolateDataFromStep2 = values;
  };

  setSelectedDecimation = (values: Decimation) => {
    this.selectedDecimation = values;
  };

  getDecimation = async (wellId: string, date: string) => {
    this.loadingForm = true;
    try {
      const result = await agent.Decimation.detail(wellId, date);
      result.surveyDate = new Date(result.surveyDate + "Z");
      return result;
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => (this.loadingForm = false));
    }
  };

  getStep3DecimationChart = async (payload: TChartStep3Payload) => {
    this.loadingChart = true;

    try {
      const result = await agent.Decimation.getStep3DecimationChart(payload);
      return result;
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => (this.loadingChart = false));
    }
  };

  getDecimationChart = async (wellId: string, date: string) => {
    this.loadingChart = true;

    try {
      const result = await agent.Decimation.getDecimationChart(wellId, date);
      return result;
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => (this.loadingChart = false));
    }
  };

  uploadDecimationTemplate = async (payload: FormData) => {
    this.loadingDecimationUploadTempalte = true;

    try {
      const result = agent.Decimation.uploadDecimataionTemplate(payload);

      return result;
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => (this.loadingDecimationUploadTempalte = true));
    }
  };

  createDecimation = async (decimation: Decimation) => {
    try {
      decimation.surveyDate = new Date(decimation.surveyDate + "Z");
      await agent.Decimation.create(decimation);
    } catch (error) {
      throw error;
    }
  };

  draftDecimation = async (decimation: Decimation) => {
    try {
      decimation.surveyDate = new Date(decimation.surveyDate + "Z");
      await agent.Decimation.draft(decimation);
    } catch (error) {
      throw error;
    }
  };

  deleteDecimation = async (id: IDeleteData) => {
    this.loadingForm = true;
    try {
      await agent.Decimation.delete(id);
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => (this.loadingForm = false));
    }
  };
}
