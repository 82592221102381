import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { ViewModule } from "@mui/icons-material";
import { Grid } from "@mui/material";
import { GridActionsCellItem, GridRowParams } from "@mui/x-data-grid";
import { useStore } from "../../../app/stores/store";
import RkdDataGrid from "../../../app/component/dataGrid/RkdDataGrid";

function PTDecimationGrid() {
  const { decimationGridStore } = useStore();
  const { getDecimationList } = decimationGridStore;
  const navigate = useNavigate();

  useEffect(() => {
    getDecimationList();
  }, [getDecimationList]);

  const gridColumns = [
    { field: "wellName", headerName: "Well", flex: 1, type: "string" },
    { field: "surveyNo", headerName: "Survey Nr", flex: 1, type: "string" },
    { field: "surveyDate", headerName: "Date", flex: 1, type: "date" },
    { field: "surveyType", headerName: "Survey Type", flex: 1, type: "string" },
    { field: "status", headerName: "Status", flex: 1, type: "string" },
    { field: "tools", headerName: "Tools", flex: 1, type: "string" },
    {
      field: "actions",
      headerName: "Action",
      type: "actions",
      flex: 1.2,
      getActions: (params: GridRowParams) => [
        <GridActionsCellItem
          icon={params.row.status === "COMPLETED" ? <VisibilityIcon /> : <VisibilityOffIcon />}
          label="View"
          color="info"
          disabled={params.row.status !== "COMPLETED"}
          onClick={() => {
            navigate(`report/${params.row.wellId}/${params.row.surveyDate.toISOString().split("T")[0]}`);
          }}
        />,
        <GridActionsCellItem
          icon={<EditIcon />}
          color="info"
          label="Edit"
          disabled={params.row.status === "COMPLETED"}
          onClick={() => {
            navigate(`edit/${params.row.wellId}/${params.row.surveyDate.toISOString().split("T")[0]}`);
          }}
        />,
        <GridActionsCellItem
          icon={<ViewModule />}
          color="info"
          label="Module"
          onClick={() => {
            navigate(`pt-module/${params.row.id}`);
          }}
          disabled={params.row.status !== "COMPLETED"}
        />,
      ],
    },
  ];

  return (
    <Grid container>
      <Grid item xs={12}>
        <RkdDataGrid columns={gridColumns} gridStore={decimationGridStore} isRowSelectable={(params: any) => params.row.status !== "COMPLETED"} />
      </Grid>
    </Grid>
  );
}

export default observer(PTDecimationGrid);
