import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { history } from "../..";
import { IExample } from "../models/example";
import { IAccountInfo, IAccountLoginValues, IAccountRegisterValues, IDeleteData } from "../models/account";
import { store } from "../stores/store";
import { IUser, IUserDetail, IUserPhoto } from "../models/user";
import { DataGridResult } from "../models/materialUI/dataGrid";
import { Roles, UNLIMITED_PAGE } from "../config/enum";
import { IObjectTypes, IObjectTypesOptions } from "../models/objectTypes";
import { IObject, IObjectOptions } from "../models/object";
import { ObjectTypeSchema } from "../../features/master/objectType/schema/objectTypeSchema";
import { ObjectSchema } from "../../features/master/object/schema/objectSchema";
import { IConnection, IConnectionOptions, IConnectionPayload, IConnectionVesselGroup } from "../models/connection";
import { ICompletionPayload, ICompletionResponse, IDirectionalPayload, IDirectionalResponse, IWellGeometry } from "../models/wellGeometry";
import { IVesselGroup, IVesselGroupOptions, IVesselGroupPayload } from "../models/vesselGroup";

import { IRegressionRequest, IRegressionResponse, ISteamMfcTable } from "../models/steam";
import { IPortLocationOption } from "../models/portLocation";
import { ITft, ITftCompletion, ITftCompletionRequest, ITftGrid, ITftReport } from "../models/tft";
import { IPtsRequest, IPtsSelectors, IPtsTable, TPTSData } from "../models/pts";
import { IObjectStatus, IObjectStatusDetail, IObjectStatusGrid } from "../models/objectStatus";
import { ILocation, ILocationOptions, ILocationPayload } from "../models/location";
import { IBrinePump, IBrinePumpOptions } from "../models/brinePump";
import { IPtsChartRequest, IPtsChartResponse } from "../models/ptsChart";
import { DecimationGrid, IDecimation, TChartStep3Payload, TUploadTemplateDecimationResponse } from "../models/decimation";
import { IFPTChartResponse, IFptDetail, IFptForm, IFptGrid } from "../models/fpt";
import {
  IGeoDeleteChild,
  IGeological,
  IGeologicalDetail,
  IGeologicalMebGrid,
  IGeologicalOption,
  IGeoMebDetail,
  IGeoMebTableRequest,
  IGeoTemplateValidator,
  TDeleteGeologicalDetail,
  TDownloadTemplatePayload,
  TDynamicGeologicalEditPayload,
  TDynamicGeologicalGridResponse,
  TGeneratePCSDataPayload,
  TGeologicalCancel,
  TGeologicalChartPayload,
  TGeologicalChartResponse,
  TGeologicalDataResponse,
  TGeologicalSubmitPayload,
  TUploadGeologicalTemplateResponse,
} from "../models/geological";
import {
  TGeophysicsGravity,
  TGeophysicsImportTemplateGravitiesResponse,
  TGeophysicsImportTemplateResponse,
  TGeophysicsMT,
  TGeophysictGravityChartResponse,
  TGeophysictMTDetailResponse,
} from "../models/geophysics";
import {
  TBiweeklyAddPayload,
  TBiweeklyChartPayload,
  TBiweeklyChartResponse,
  TBiweeklyChartSelectorResponse,
  TBiweeklyEditPerRowPayload,
  TBiweeklyGridResponse,
  TBiweeklyResponse,
  TBiweeklyResponseAfterUploadTemplate,
  TBrineEcolabDetailResponse,
  TBrineEcolabPayload,
  TBrineORCChartLeftRightYAxis,
  TBrineORCChartPayload,
  TBrineORCChartResponse,
  TBrineORCChartRightYAxisPayload,
  TBrineORCDetailDataAfterUploadResponse,
  TBrineORCDetailResponse,
  TBrineORCGridResponse,
  TBrineORCReportGridResponse,
  TBrineOrcMonitoringPayload,
  TCleanDetailResponse,
  TCleanGridDetailPayload,
  TCleanGridDetailResponse,
  TCleanOoutHistoryGridPayload,
  TCleanOoutHistoryGridResponse,
  TGeneralTextValue,
} from "../models/geochemistry";
import { TOrcGridResponse, TOrcOptions, TOrcPayload } from "../models/orc";
import { TCompleteOrcGroupDetailResponse, TOrcGroupDetailResponse, TOrcGroupGridResponse, TOrcGroupPayload } from "../models/orcGroup";
import {
  TDataResponseQuarterlyUploadTemplate,
  TGridQuarterlyResponse,
  TQuarterlyChartPayload,
  TQuarterlyChartResponse,
  TQuarterlyPayload,
  TQuarterlyTablePayload,
  TYAxesResponse,
} from "../models/quarterly";
import { IDailySheet, TCopyPreviousDailySheetPayload, TDailySheetPayload, TDailySheetResponse } from "../models/dailysheet";
import { IQueryChartResponse, IQueryGetSelector, IQueryRequest, TChartSelectorObject } from "../models/query";
import {
  TPtModuleDetailResponse,
  TPtModuleDetailDataResponse,
  TPtModuleWellReferenceOptionsResponse,
  TPtModuleCorrectionReferences,
  TPtReportWellOptions,
  TPtReportWellDataPayload,
  TPtReportWellDataResponse,
  TPtReportDownloadPayload,
} from "../models/ptModule";

const sleep = (delay: number) => {
  return new Promise((resolve) => {
    setTimeout(resolve, delay);
  });
};

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

axios.interceptors.request.use((config) => {
  const token = store.commonStore.token;
  if (token && config.headers) config.headers.Authorization = `Bearer ${token}`;
  return config;
});

axios.interceptors.response.use(
  async (response) => {
    if (process.env.NODE_ENV === "development") await sleep(1500);
    response = readResponseHeader(response);
    return response;
  },
  (error: AxiosError<any>) => {
    const { data, status, config } = error.response!;
    console.log(error.response!);

    switch (status) {
      case 400:
        if (typeof data === "string") {
          store.snackbarStore.show("error", data);
        }
        if (config.method === "get" && data.errors.hasOwnProperty("id")) {
          history.push("/not-found");
        }
        if (data.errors) {
          const modalStateErrors = [];
          for (const key in data.errors) {
            if (data.errors[key]) {
              modalStateErrors.push(" " + data.errors[key]);
            }
          }
          // throw modalStateErrors.flat();
          store.snackbarStore.show("error", modalStateErrors.join());
        }
        break;
      case 401:
        store.snackbarStore.show("error", "Error 401 (Unauthorized)");
        break;
      case 403:
        store.snackbarStore.show("error", "Error 403 (Forbidden)");
        break;
      case 404:
        history.push("/not-found");
        break;
      case 500:
        store.commonStore.setServerError(data);
        history.push("/server-error");
        break;
    }
    return Promise.reject(error);
  }
);

const readResponseHeader = (response: AxiosResponse): AxiosResponse => {
  if (response.headers["rowcount"]) {
    // data grid result
    response.data = new DataGridResult(response.data, parseInt(response.headers["rowcount"]));
    return response as AxiosResponse<DataGridResult<any>>;
  }
  return response;
};

export const handleBlobResponse = (response: AxiosResponse<Blob>) => {
  try {
    // create file link in browser's memory
    const blob = new Blob([response.data], {
      type: response.headers["content-type"],
    });
    const href = URL.createObjectURL(blob);

    // create "a" HTML element with href to file & click
    const link = document.createElement("a");
    link.href = href;
    const filename = response.headers["content-disposition"].split("filename")[1].replace(/[=; "]/g, "");

    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();

    // clean up "a" element & remove ObjectURL
    document.body.removeChild(link);
    URL.revokeObjectURL(href);
  } catch (error) {
    store.snackbarStore.show("error", "File Not Found");
    throw error;
  }
};

const responseBody = <T>(response: AxiosResponse<T>) => response.data;

const requests = {
  get: <T>(url: string, body?: {}) => axios.get<T>(url, body).then(responseBody),
  getFile: (url: string, body?: AxiosRequestConfig<any>) =>
    axios.get<Blob>(url, { ...body, responseType: "blob" }).then((response) => handleBlobResponse(response)),
  post: <T>(url: string, body?: {}) => axios.post<T>(url, body).then(responseBody),
  put: <T>(url: string, body?: {}) => axios.put<T>(url, body).then(responseBody),
  del: <T>(url: string, body?: {}) => axios.delete<T>(url, { data: body }).then(responseBody),
  getFileWithPost: (url: string, body?: {}) => axios.post<Blob>(url, body, { responseType: "blob" }).then((response) => handleBlobResponse(response)),
  postFile: <T>(url: string, body?: {}) => axios.post<T>(url, body, { headers: { "Content-Type": "multipart/form-data" } }).then(responseBody),
};

const Account = {
  current: () => requests.get<IAccountInfo>("/account"),
  login: (user: IAccountLoginValues) => requests.post<IAccountInfo>("/account/login", user),
  register: (user: IAccountRegisterValues) => requests.post<IAccountInfo>("/account/register", user),
};

const User = {
  list: (params: URLSearchParams) => requests.get<DataGridResult<IUser[]>>("/user", { params }),
  detail: (username: string) => requests.get<IUserDetail>(`/user/${username}`),
  create: (user: IUser) => requests.post<void>("/user", user),
  edit: (user: IUser) => requests.put<void>(`/user/${user.username}`, user),
  delete: (username: string) => requests.del<void>(`/user/${username}`),
  upload: (data: IUserPhoto) => requests.postFile<void>(`/user/${data.username}/photo`, data.file),
  addRole: (username: string, role: Roles) => requests.post<void>(`/user/${username}/role`, { name: role }),
  removeRole: (username: string, role: Roles) => requests.del<void>(`/user/${username}/role`, { name: role }),
  resetPass: (username: string) => requests.put<void>(`/user/${username}/reset`),
};

const Role = {};

const Example = {
  list: (params: URLSearchParams) => requests.get<IExample[]>("/example", { params }), // name must exactly "params"
  listGrid: (params: URLSearchParams) => requests.get<DataGridResult<IExample[]>>("/example/grid", { params }), // because it's a field called "params"
  detail: (id: string) => requests.get<IExample>(`/example/${id}`),
  create: (example: IExample) => requests.post<void>("/example", example),
  edit: (example: IExample) => requests.put<void>(`/example/${example.id}`, example),
  delete: (id: string) => requests.del<void>(`/example/${id}`),
};

const ObjectType = {
  listGrid: (params: URLSearchParams) => requests.get<DataGridResult<IObjectTypes[]>>("/objectType/grid", { params }),
  create: (objectType: ObjectTypeSchema) => requests.post<void>("/objectType", objectType),
  edit: (id: string, objectType: ObjectTypeSchema) => requests.put<void>(`/objectType/${id}`, objectType),
  delete: (id: IDeleteData) => requests.del<void>(`/objectType/`, id),
  detail: (id: string) => requests.get<IObjectTypes>(`/objectType/${id}`),
  listOptions: (params: URLSearchParams) => requests.get<IObjectTypesOptions[]>("/objectType/options", { params }),
};

const Object = {
  listGrid: (params: URLSearchParams) => requests.get<DataGridResult<IObject[]>>("/object/grid", { params: { ...params, pageSize: UNLIMITED_PAGE } }),
  dropdown: () => requests.get<IObject[]>("/object"),
  create: (object: ObjectSchema) => requests.post<void>("/object", object),
  edit: (id: string, object: ObjectSchema) => requests.put<void>(`/object/${id}`, object),
  delete: (id: IDeleteData) => requests.del<void>(`/object/`, id),
  detail: (id: string) => requests.get<IObject>(`/object/${id}`),
  listOptions: (params: URLSearchParams) => requests.get<IObjectOptions[]>("/object/options", { params }),
  listOptionsByLocationId: (params: URLSearchParams) => requests.get<IObjectOptions[]>("/object/production/options", { params }),
};

const VesselGroup = {
  listGrid: (params: URLSearchParams) => requests.get<DataGridResult<IVesselGroup[]>>("/vesselgroup/grid", { params }),
  listOptions: (params: URLSearchParams) => requests.get<IVesselGroupOptions[]>("/vesselgroup/options", { params }),
  detail: (id: string) => requests.get<IVesselGroup>(`/vesselgroup/${id}`),
  create: (vesselGroup: IVesselGroupPayload) => requests.post<void>("/vesselgroup", vesselGroup),
  edit: (id: string, vesselGroup: IVesselGroupPayload) => requests.put<void>(`/vesselgroup/${id}`, vesselGroup),
  delete: (id: IDeleteData) => requests.del<void>(`/vesselgroup/`, id),
};

const ORC = {
  getOrcGrid: (params: URLSearchParams) =>
    requests.get<DataGridResult<TOrcGridResponse[]>>("/orc/grid", { params: { ...params, pageSize: UNLIMITED_PAGE } }),
  listOptions: (params: URLSearchParams) => requests.get<IVesselGroupOptions[]>("/vesselgroup/options", { params }),
  addOrc: (payload: TOrcPayload) => requests.post<void>("/orc", payload),
  editOrc: (id: string, payload: TOrcPayload) => requests.put<void>(`orc/${id}`, { ...payload, id }),
  getOrcDetail: (id: string) => requests.get<TOrcGridResponse>(`/orc/${id}`),
  deleteOrc: (ids: IDeleteData) => requests.del<void>(`/orc/`, ids),
  getORCOption: () => requests.get<TOrcOptions[]>(`/orc/options`),

  // ORC Group
  getOrcGroupGrid: (params: URLSearchParams) =>
    requests.get<DataGridResult<TOrcGroupGridResponse[]>>("/orc/group/grid", { params: { ...params, pageSize: UNLIMITED_PAGE } }),
  addOrcGroup: (payload: TOrcGroupPayload) => requests.post<void>("/orc/group", payload),
  getOrcGroupDetail: (id: string) => requests.get<TCompleteOrcGroupDetailResponse>(`/orc/group/${id}`),
  getOrcGroupOptions: () => requests.get<TGeneralTextValue[]>("/orc/group/options"),
  editOrcGroup: (id: string, payload: TOrcGroupPayload) => requests.put<void>(`orc/${id}`, { ...payload, id }),
  deleteOrcGroup: (ids: IDeleteData) => requests.del<void>(`/orc/group`, ids),
};

const Connection = {
  listGrid: (params: URLSearchParams) => requests.get<DataGridResult<IConnection[]>>("/connection/grid", { params }),
  detail: (id: string | undefined) => requests.get<IConnection>(`/connection/${id}`),
  listOptions: (params: URLSearchParams) => requests.get<IConnectionOptions[]>("/connection/options", { params }),
  getVesselGroup: (id: string) => requests.get<IConnectionVesselGroup[]>(`/connection/vessel-groups/${id}`),
  detailConnectionVesselGroup: (id: string) => requests.get<IConnectionVesselGroup>(`/connection/vessel-groups/${id}`),
  create: (connection: IConnectionPayload) => requests.post<void>("/connection", connection),
  edit: (id: string, connection: IConnectionPayload) => requests.put<void>(`/connection/${id}`, connection),
  delete: (id: IDeleteData) => requests.del<void>(`/connection/`, id),
};

const Geometry = {
  listGrid: (params: URLSearchParams) => requests.get<DataGridResult<IWellGeometry[]>>("/object/geometry/grid", { params }),
  createDirectional: (directional: IDirectionalPayload) => requests.post<void>("/object/geometry/directional", directional),
  editDirectional: (directional: IDirectionalPayload) => requests.put<void>(`/object/geometry/directional/${directional.objectId}`, directional),
  deleteDirectional: (id: string) => requests.del<void>(`/object/geometry/directional/${id}`),
  detailDirectional: (id: string) => requests.get<IDirectionalResponse>(`/object/geometry/directional/${id}`),
  createCompletion: (completion: ICompletionPayload) => requests.post<void>("/object/geometry/completion", completion),
  editCompletion: (completion: ICompletionPayload) => requests.put<void>(`/object/geometry/completion/${completion.objectId}`, completion),
  deleteCompeltion: (id: string) => requests.del<void>(`/object/geometry/completion/${id}`),
  detailCompletion: (id: string) => requests.get<ICompletionResponse>(`/object/geometry/completion/${id}`),
  deleteWellGeometry: (ids: IDeleteData) => requests.del<void>("/object/geometry", ids),
};

const ObjectStatus = {
  listGrid: (params: URLSearchParams) =>
    requests.get<DataGridResult<IObjectStatusGrid[]>>("/object/status/grid", { params: { ...params, pageSize: UNLIMITED_PAGE } }),
  create: (objStats: IObjectStatus) => requests.post<void>("/object/status", objStats),
  detail: (id: string) => requests.get<IObjectStatusDetail[]>(`/object/status/detail/${id}`),
};

const Location = {
  listGrid: (params: URLSearchParams) => requests.get<DataGridResult<ILocation[]>>("/location/grid", { params }),
  create: (location: ILocationPayload) => requests.post<void>("/location", location),
  delete: (id: IDeleteData) => requests.del<void>(`/location/`, id),
  edit: (id: string, location: ILocation) => requests.put<void>(`/location/${id}`, location),
  detail: (id: string) => requests.get<ILocation>(`/location/${id}`),
  listOptions: (params: URLSearchParams) => requests.get<ILocationOptions[]>("/location/options", { params }),
};

const BrinePump = {
  listGrid: (params: URLSearchParams) => requests.get<DataGridResult<IBrinePump[]>>("/brinePump/grid", { params }),
  create: (brinePump: IBrinePump) => requests.post<void>("/brinePump", brinePump),
  edit: (id: string, data: IBrinePump) => requests.put<void>(`/brinePump/${id}`, data),
  detail: (id: string) => requests.get<IBrinePump>(`/brinePump/${id}`),
  delete: (id: IDeleteData) => requests.del<void>(`/brinePump/`, id),
  listOptions: (params: URLSearchParams) => requests.get<IBrinePumpOptions[]>("/brinePump/options", { params }),
};

const TFT = {
  regression: (type: string, data: IRegressionRequest) => requests.post<IRegressionResponse[]>(`/polynomialregression/${type}`, data),
  draft: (tft: ITft) => requests.post<void>("/tft/draft", tft),
  create: (tft: ITft) => requests.post<void>("/tft", tft),
  detail: (date: string, objectId: string) => requests.get<ITft>(`/tft/${date}/${objectId}`),
  listGrid: (params: URLSearchParams) => requests.get<DataGridResult<ITftGrid[]>>("/tft/grid", { params }),
  delete: (ids: IDeleteData) => requests.del(`/tft`, ids),
  report: (id: string) => requests.get<ITftReport>(`/tft/report/${id}`),
  completion: (data: ITftCompletionRequest) => requests.get<ITftCompletion>("/tft/completion", data),
  mfcFlowRate: (steamId: string, sequence: number) => requests.get<ISteamMfcTable[]>(`/tft/mfc-flow-rate/${steamId}/${sequence}`),
};

const portLocation = {
  listOptions: (params: URLSearchParams) => requests.get<IPortLocationOption[]>("/portlocation/options", { params }),
};

const PTS = {
  detail: (objectId: string, date: string) => requests.get<IPtsRequest>(`/Pts/${date}/${objectId}`),
  create: (pts: IPtsRequest) => requests.post<void>("/Pts", pts),
  listOptions: (params: URLSearchParams) => requests.get<IPtsSelectors[]>("/Pts/selectors", { params }),
  chart: (pts: IPtsChartRequest) => requests.post<IPtsChartResponse>("/Pts/chart", pts),
  listGrid: (params: URLSearchParams) => requests.get<DataGridResult<IPtsTable[]>>("/Pts/grid", { params }),
  delete: (id: string) => requests.del<void>(`/Pts/${id}`),

  getPTSFile: (id: string, fileUrl: string) => requests.getFile(`/Pts/download-file/${id}/${fileUrl}`),

  getDetailPTS: (ptsId: string) => requests.get<TPTSData>(`/Pts/${ptsId}`),
  addPTS: (body: any) => requests.post("/Pts", body),
  editPTS: (body: any, ptsId: string) => requests.put(`/Pts/${ptsId}`, body),
  deletePTS: (ids: IDeleteData) => requests.del(`/Pts`, ids),
};

const Decimation = {
  listGrid: (params: URLSearchParams) => requests.get<DataGridResult<DecimationGrid[]>>("/pressuretemp/decimation/grid", { params }),
  detail: (wellId: string, date: string) => requests.get<IDecimation>(`/pressuretemp/decimation/${wellId}/${date}`),
  create: (decimation: IDecimation) => requests.post<void>("/pressuretemp/decimation", decimation),
  draft: (decimation: IDecimation) => requests.post<void>("/pressuretemp/decimation/draft", decimation),
  delete: (id: IDeleteData) => requests.del<void>(`/pressuretemp/decimation`, id),
  getDecimationChart: (wellId: string, date: string) => requests.get<IFPTChartResponse>(`/pressuretemp/decimation/chart/${wellId}/${date}`),
  getStep3DecimationChart: (payload: TChartStep3Payload) => requests.post<IFPTChartResponse>("/pressuretemp/decimation/chart/", payload),
  uploadDecimataionTemplate: (payload: FormData) => requests.post<TUploadTemplateDecimationResponse>("pressuretemp/decimation/import-excel", payload),
};

const PtModule = {
  detail: (decimationId: string) => requests.get<TPtModuleDetailResponse>(`/pressuretemp/pt-first-module/${decimationId}`),
  getWellReferenceOptions: (excludeWellId: string, excluedeSurveyDate: Date) =>
    requests.get<TPtModuleWellReferenceOptionsResponse[]>(`/pressuretemp/decimation/well-reference/options/${excludeWellId}/${excluedeSurveyDate}`),
  getDecimationReference: (decimationId: string, date: string) =>
    requests.get<TPtModuleCorrectionReferences[]>(`/pressuretemp/decimation/reference/${decimationId}/${date}`),
  getPtReportWellOptions: (date: string) => requests.get<TPtReportWellOptions[]>(`/pressuretemp/pt-report/well/options/${date}`),
  getPtReportWellData: (payload: TPtReportWellDataPayload) => requests.post<TPtReportWellDataResponse[]>("pressuretemp/pt-report/well-data", payload),
  downloadDecimationReport: (payload: TPtReportDownloadPayload) => requests.getFileWithPost("pressuretemp/pt-report/download-excel", payload),
  create: (decimationPayload: TPtModuleDetailDataResponse) => requests.post<void>("/pressuretemp/pt-first-module", decimationPayload),
};

const FPT = {
  listGrid: (params: URLSearchParams) => requests.get<DataGridResult<IFptGrid[]>>("/flowPressureTest/grid", { params }),
  detail: (wellId: string, date: string) => requests.get<IFptDetail>(`/flowPressureTest/${wellId}/${date}`),
  create: (fpt: IFptForm) => requests.post<void>("/flowPressureTest", fpt),
  delete: (id: IDeleteData) => requests.del<void>(`/flowPressureTest`, id),
  chart: (wellId: string, date: string) => requests.get<IFPTChartResponse>(`/flowPressureTest/chart/${wellId}/${date}`),
  getObjectOptions: () => requests.get<IObjectOptions[]>("/flowPressureTest/object/options"),
};

const Geochemistry = {
  // BIWEEKLY
  getBiweeklyLeftYAxis: () => requests.get<TGeneralTextValue[]>("/biweekly/chart/left-yAxes"),
  getBiweeklyRightYAxis: () => requests.get<TGeneralTextValue[]>("/biweekly/chart/right-yAxes"),
  getBiweeklyGrid: (params: URLSearchParams) =>
    requests.get<DataGridResult<TBiweeklyGridResponse[]>>("/biweekly/grid", { params: { ...params, pageSize: UNLIMITED_PAGE } }),
  getBiweekly: (date: string) => requests.get<TBiweeklyResponse>(`/biweekly/${date}`),
  getBiweeklyTemplate: (date: string) => requests.getFile(`/biweekly/template/${date}`),
  getBiweeklyChart: (payload: TBiweeklyChartPayload) => requests.post<TBiweeklyChartResponse[]>("/biweekly/chart/", payload),
  getBiweeklyChartSelector: () => requests.get<TBiweeklyChartSelectorResponse[]>(`/biweekly/chart/selector`),
  addBiweekly: (payload: TBiweeklyAddPayload) => requests.post<void>("biweekly", payload),
  editBiweeklyPerRow: (payload: TBiweeklyEditPerRowPayload) => requests.put<void>("biweekly/detail", payload),
  uploadBiweeklyTemplate: (payload: FormData) => requests.post<TBiweeklyResponseAfterUploadTemplate>("biweekly/import-excel", payload),
  deleteBiweekly: (ids: IDeleteData) => requests.del(`/biweekly`, ids),

  // CLEAN OUT HISTORY
  getCleanOutHistoryGrid: (payload: TCleanOoutHistoryGridPayload) => requests.post<TCleanOoutHistoryGridResponse>("cleaning/history", payload),
  getCleanOutDetailGrid: (payload: TCleanGridDetailPayload) =>
    requests.get<TCleanGridDetailResponse>(`cleaning/grid/${payload.startDate}/${payload.endDate}`),
  deleteCleanOutDetailGrid: (ids: IDeleteData) => requests.del(`/cleaning`, ids),
  addCleanOutDetail: (payload: FormData) => requests.post<void>("/cleaning", payload),
  editCleanOutDetail: (payload: FormData, id: string) => requests.put<void>(`/cleaning/${id}`, payload),
  getCleanOutDetail: (id: string) => requests.get<TCleanDetailResponse>(`/cleaning/${id}`),

  // BRINE ORC MONITORING
  getBrineOrcTemplate: (date: string) => requests.getFile(`/brineorc/template/${date}`),
  uploadBrineOrcTemplate: (payload: FormData) => requests.post<TBrineORCDetailDataAfterUploadResponse>("/brineorc/import-excel", payload),
  getBrineORCMonitoringGrid: (params: URLSearchParams) =>
    requests.get<DataGridResult<TBrineORCGridResponse[]>>("/brineorc/grid", { params: { ...params, pageSize: UNLIMITED_PAGE } }),
  addEditBrineOrcMonitoring: (payload: TBrineOrcMonitoringPayload) => requests.post<void>("/brineorc/monitoring", payload),
  deleteBrineORCMonitoringGrid: (ids: IDeleteData) => requests.del(`/brineorc`, ids),
  getBrineORCMonitoringDetail: (date: string) => requests.get<TBrineORCDetailResponse>(`/brineorc/monitoring/${date}`),
  // BRINE ORC ECOLAB
  getBrineEcolab: (date: string) => requests.get<TBrineEcolabDetailResponse>(`/brineorc/ecolab/${date}`),
  addEditBrineEcolab: (payload: TBrineEcolabPayload) => requests.post<void>("/brineorc/ecolab", payload),
  // BRINE ORC REPORT
  getBrineORCChartLeftYAxis: () => requests.get<TBrineORCChartLeftRightYAxis[]>("/brineorc/chart/left-yAxes"),
  getBrineORCChartRightYAxis: (payload: TBrineORCChartRightYAxisPayload) =>
    requests.post<TBrineORCChartLeftRightYAxis[]>("/brineorc/chart/right-yAxes", payload),
  getBrineORCChart: (payload: TBrineORCChartPayload) => requests.post<TBrineORCChartResponse>("/brineorc/chart", payload),
  getBrineORCReportGrid: (orcGroupId: string, startDate: string, endDate: string) =>
    requests.get<TBrineORCReportGridResponse>(`/brineOrc/chart/table/${orcGroupId}/${startDate}/${endDate}`),

  // QUARTERLY REPORT
  getQuarterlyGrid: (params: URLSearchParams) =>
    requests.get<DataGridResult<TGridQuarterlyResponse[]>>("/quarterly/grid", { params: { ...params, pageSize: UNLIMITED_PAGE } }),
  addQuarterly: (payload: TQuarterlyPayload) => requests.post<void>("/quarterly", payload),
  editQuarterly: (payload: TQuarterlyTablePayload) => requests.put<void>("/quarterly/detail", payload),
  deleteQuarterly: (ids: IDeleteData) => requests.del(`/quarterly`, ids),
  getQuarterlyDetail: (wellId: string) => requests.get<TDataResponseQuarterlyUploadTemplate>(`/quarterly/${wellId}`),
  deleteQuarterlyDetail: (ids: IDeleteData) => requests.del(`/quarterly/detail`, ids),
  editQuarterlyDetail: (payload: any) => requests.put<void>("/quarterly/detail", payload),
  downloadTemplateQuarterly: (wellId: string) => requests.getFile(`/quarterly/template/${wellId}`),
  uploadTemplateQuarterly: (payload: FormData) => requests.post<TDataResponseQuarterlyUploadTemplate>("/quarterly/import-excel", payload),

  // QUARTERLY REPORT CHART
  getQuarterlyAxes: (identifier: string) => requests.get<TYAxesResponse[]>(`/quarterly/chart/${identifier}-yAxes`),
  getQuarterlCharts: (payload: TQuarterlyChartPayload) => requests.post<TQuarterlyChartResponse>(`/quarterly/chart`, payload),
};

const Geological = {
  listGrid: (params: URLSearchParams) => requests.get<DataGridResult<IGeological[]>>("/Geological/grid", { params }),
  listGridMeb: (params: URLSearchParams) => requests.get<DataGridResult<IGeologicalMebGrid[]>>("/Geological/meb/grid", { params }),
  downloadTemplate: (payload: TDownloadTemplatePayload) => requests.getFileWithPost(`Geological/template`, payload),
  downloadTemplatePCS: (type: number, wellId: string) => requests.getFile(`Geological/template/${type}/${wellId}`),
  templateValidator: (data: IGeoTemplateValidator) => requests.postFile<void>(`Geological/template/validator`, data),
  listWellOption: (type: number) => requests.get<IGeologicalOption[]>(`/Geological/well/options?type=${type}`),
  createLithology: (data: any) => requests.post<void>("Geological/lithology", data),
  createDrillParam: (data: any) => requests.post<void>("Geological/drill-parameter", data),
  createPcs: (data: any) => requests.post<void>("Geological/pcs", data),
  createFeedZone: (data: any) => requests.post<void>("Geological/feed-zone", data),
  createTemperature: (data: any) => requests.post<void>("Geological/temperature", data),
  createLoss: (data: any) => requests.post<void>("Geological/loss", data),
  createAlteration: (data: any) => requests.post<void>("Geological/alteration", data),
  createMeb: (data: any) => requests.post<void>("Geological/meb", data),
  optionsLithology: () => requests.get<IGeologicalOption[]>("/Geological/lithology/options"),
  optionsAlteration: () => requests.get<IGeologicalOption[]>("/Geological/alteration/options"),
  optionsFormation: () => requests.get<IGeologicalOption[]>("/Geological/formation/options"),
  detailGeological: (id: string) => requests.get<IGeologicalDetail>(`Geological/${id}`),
  detailGeologicalTable: (type: string, id: string) =>
    requests.get<TDynamicGeologicalGridResponse[]>(`Geological/${type}/grid/${id}`, { params: { pageSize: UNLIMITED_PAGE } }),
  detailGeologicalMeb: (id: string) => requests.get<IGeoMebDetail>(`Geological/meb/${id}`),
  detailGeologicalMebTable: (id: string) => requests.get<IGeoMebTableRequest>(`Geological/meb/grid/${id}`),
  deleteGeological: (id: IDeleteData) => requests.del<void>(`Geological`, id),
  deleteGeologicalTable: (data: IGeoDeleteChild) => requests.del<void>(`Geological/detail`, data),
  deleteGeologicalMeb: (id: IDeleteData) => requests.del<void>(`Geological/meb/`, id),
  deleteGeologicalMebTable: (id: IDeleteData) => requests.del<void>(`Geological/meb/detail`, id),
  cancelGeologicalAdd: (payload: TGeologicalCancel) => requests.post<void>("Geological/cancel", payload),
  uploadGeologicalTemplate: (payload: FormData) => requests.post<TUploadGeologicalTemplateResponse>("Geological/import-excel", payload),
  getGridAfterUploadGeological: (params: URLSearchParams, type: string, id: string) =>
    requests.get<DataGridResult<TDynamicGeologicalGridResponse[]>>(`Geological/${type}/grid/${id}`, { params: { pageSize: UNLIMITED_PAGE } }),
  getGeologicalChart: (payload: TGeologicalChartPayload) => requests.post<TGeologicalChartResponse[]>("geological/chart", payload),
  submitGeologicalAdd: (payload: TGeologicalSubmitPayload) => requests.post("Geological/submit", payload),
  generatePCSData: (payload: TGeneratePCSDataPayload) => requests.post("geological/generate-pcs", payload),
  addGeological: (type: string, payload: TGeologicalDataResponse) => requests.post(`geological/${type}`, payload),
  editGeological: (payload: TDynamicGeologicalEditPayload, type: string) => requests.put<void>(`/geological/${type}/${payload.id}`, payload),
  deleteGeologicalDetail: (params: TDeleteGeologicalDetail[]) => requests.del("Geological/detail", { params }),
};

const Geophysic = {
  getGeophysicMTGrid: (params: URLSearchParams) => requests.get<DataGridResult<TGeophysicsMT[]>>("Geophysic/mt/grid", { params }),
  getGeophysicMTDetail: (id: string) => requests.get<TGeophysictMTDetailResponse>(`Geophysic/mt/${id}`),
  getGeophysicGravityGrid: (params: URLSearchParams) => requests.get<DataGridResult<TGeophysicsGravity[]>>("Geophysic/gravity/grid", { params }),
  getGeophysicGravityDataConversionGrid: (params: URLSearchParams, id: string) =>
    requests.get<DataGridResult<TGeophysicsImportTemplateGravitiesResponse[]>>(`Geophysic/gravity/grid/${id}`, { params }),
  getGeophysicGravityDetail: (id: string) => requests.get<TGeophysicsGravity>(`Geophysic/gravity/${id}`),
  addGeophysicGravity: (payload: any) => requests.post<void>("/geophysic/gravity", payload),
  getGeophysicGravityChart: (id: string) => requests.get<TGeophysictGravityChartResponse>(`geophysic/gravity/chart/${id}`),
  addGeophysicMT: (payload: FormData) => requests.post<void>("/geophysic/mt", payload),
  downloadTemplateGravity: (locationID: string, year: string) => requests.getFile(`geophysic/gravity/template/${locationID}/${year}`),
  uploadTemplateGravity: (payload: FormData) => requests.post<TGeophysicsImportTemplateResponse>("/geophysic/gravity/import-template", payload),
  deleteGeophysicGravity: (ids: IDeleteData) => requests.del(`/Geophysic/gravity`, ids),
  deleteGeophysicMT: (ids: IDeleteData) => requests.del(`/Geophysic/mt`, ids),
  getStationOptions: (params: URLSearchParams) => requests.get<ILocationOptions[]>("/station/options", { params }),
};

// MIGRATE FROM DAILY LOG
const Dailysheet = {
  getByDate: (params: URLSearchParams) => requests.get<IDailySheet>(`/DailyLog/date/`, { params }),
  getDailySheet: (date: string) => requests.get<TDailySheetResponse>(`/dailyLog/${date}`),
  addDailySheet: (isDraft: boolean, data: TDailySheetPayload) => requests.post<void>(`/dailyLog/${isDraft ? "draft" : ""}`, data),
  copyPreviousDailySheet: (data: TCopyPreviousDailySheetPayload) => requests.post<void>("/dailyLog/copy-previous", data),
};

const Query = {
  list: () => requests.get<IQueryGetSelector>(`/DailyLog/chart/selectors`),
  objectList: () => requests.get<TChartSelectorObject[]>(`/dailyLog/chart/selector/object`),
  fieldList: () => requests.get<TChartSelectorObject[]>(`/dailyLog/chart/selector/field`),
  getChartData: (data: IQueryRequest) => requests.post<IQueryChartResponse[]>("/dailyLog/chart", data),
};

const agent = {
  Account,
  User,
  Role,
  Example,
  ObjectType,
  Object,
  Connection,
  VesselGroup,
  TFT,
  portLocation,
  Geometry,
  ObjectStatus,
  Geochemistry,
  Location,
  BrinePump,
  Geological,
  PTS,
  Decimation,
  Geophysic,
  ORC,
  FPT,
  Dailysheet,
  Query,
  PtModule,
};

export default agent;
