import { observer } from "mobx-react-lite";
import ReactApexChart from "react-apexcharts";
import { Box, CircularProgress } from "@mui/material";
import { useStore } from "../../../../app/stores/store";
import { TBiweeklyChartResponse } from "../../../../app/models/geochemistry";

type TBiweeklyChartProps = {
  biweeklyChart: TBiweeklyChartResponse;
};

function BiweeklyChart({ biweeklyChart }: TBiweeklyChartProps) {
  const { biweeklyStore } = useStore();

  const options: ApexCharts.ApexOptions = {
    chart: {
      height: "20px",
    },
    xaxis: {
      type: "category",
      labels: {
        show: true,
        rotate: -45,
        rotateAlways: false,
      },
    },
    markers: {
      size: 6,
    },
    yaxis: [
      {
        title: {
          text:
            biweeklyChart?.lefts.map((item) => item.name).join(", ").length ?? 0 > 50
              ? biweeklyChart?.lefts
                  .map((item) => item.name)
                  .join(", ")
                  .slice(0, 50) + "..."
              : biweeklyChart?.lefts.map((item) => item.name).join(", "),
        },
        reversed: true,
      },
      {
        title: {
          text:
            biweeklyChart?.rights.map((item) => item.name).join(", ").length ?? 0 > 50
              ? biweeklyChart?.rights
                  .map((item) => item.name)
                  .join(", ")
                  .slice(0, 50) + "..."
              : biweeklyChart?.rights.map((item) => item.name).join(", "),
        },
        opposite: true,
        reversed: true,
      },
    ],
  };

  return (
    <Box sx={{ height: "100%" }}>
      {biweeklyStore.loadingChart ? (
        <Box sx={{ textAlign: "center" }}>
          <CircularProgress />
        </Box>
      ) : (
        <ReactApexChart
          allowDuplicatedCategory={false}
          height={400}
          type="bubble"
          options={options}
          series={[...(biweeklyChart?.lefts ?? []), ...(biweeklyChart?.rights ?? [])]}
        />
      )}
    </Box>
  );
}

export default observer(BiweeklyChart);
