import { Autocomplete, Box, TextField } from "@mui/material";
import { TStepProps } from "../utils/type";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import Step1Grid from "./Step1Grid";
import Step1GridReference from "./Step1GridReference";
import { format } from "date-fns";
import { useStore } from "../../../../app/stores/store";
import { observer } from "mobx-react-lite";
import { mergeAndInterpolateReferenceData } from "../utils/utils";
import { useEffect } from "react";

function Step1({ values, setSelectedWell, selectedWell, setFieldValue, wellOptions, errors, handleChange }: TStepProps) {
  const { ptModuleStore } = useStore();
  const { ptModuleWellReferenceOptions, getDecimationReference } = ptModuleStore;

  const handleChangeRefDate = (event: any) => setFieldValue("step1.decimationRefDate", format(new Date(event), "yyyy-MM-dd"));
  const handleChangeRefWell = (decimationId: string) => setFieldValue("step1.decimationRefWellId", decimationId);

  useEffect(() => {
    if (!values.step1?.decimationRefWellId || !values.step1?.decimationRefDate) return;

    getDecimationReference(values.step1?.decimationRefWellId, values.step1?.decimationRefDate).then((apiResult: any) => {
      const res = mergeAndInterpolateReferenceData(apiResult, values.step1?.correctionMeasureds);

      setFieldValue("step1.correctionReferences", res);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.step1?.decimationRefWellId, values.step1?.decimationRefDate]);

  return (
    <Box>
      <Box sx={{ display: "flex", flex: 2 }}>
        <TextField
          label="WHP"
          sx={{ width: "180px" }}
          required
          type="number"
          InputLabelProps={{
            shrink: true,
          }}
          name="step1.whp"
          value={values.step1?.whp}
          error={!!errors.step1?.whp}
          helperText={errors.step1?.whp}
          onChange={handleChange}
        />
      </Box>
      <Box sx={{ my: "12px" }}>
        <Step1Grid setFieldValue={setFieldValue} values={values} />
      </Box>
      <Box sx={{ display: "flex", gap: "12px" }}>
        <Autocomplete
          disablePortal
          fullWidth
          sx={{ marginBotom: 0 }}
          onChange={(e: any, val: any) => {
            setSelectedWell(val);
            handleChangeRefWell(val.value);
          }}
          disableClearable
          value={selectedWell}
          getOptionLabel={(option) => option.label}
          options={ptModuleWellReferenceOptions.map((item) => ({ label: item.wellName, value: item.wellId }))}
          renderInput={(params) => <TextField value={selectedWell?.value} {...params} label="Ref Well" />}
        />
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            label="Ref Date"
            disabled={!values.step1?.decimationRefWellId}
            onChange={handleChangeRefDate}
            value={values.step1.decimationRefDate}
            renderInput={(props) => <TextField fullWidth label="Date" {...props} />}
          />
        </LocalizationProvider>
      </Box>
      <Box sx={{ mt: "12px" }}>
        <Step1GridReference setFieldValue={setFieldValue} values={values} />
      </Box>
    </Box>
  );
}

export default observer(Step1);
