import { observer } from "mobx-react-lite";
import ReactApexChart from "react-apexcharts";
import { Box, CircularProgress } from "@mui/material";
import { useStore } from "../../../../app/stores/store";

function BrineORCChart() {
  const { brineORCMonitoringStore } = useStore();

  const options: ApexCharts.ApexOptions = {
    chart: {
      height: "20px",
    },
    xaxis: {
      type: "category",
      labels: {
        show: true,
        rotate: -45,
        rotateAlways: false,
      },
    },
    markers: {
      size: 6,
    },
    yaxis: [
      {
        title: {
          text:
            brineORCMonitoringStore.brineORCChart?.lefts.map((item) => item.name).join(", ").length ?? 0 > 50
              ? brineORCMonitoringStore.brineORCChart?.lefts
                  .map((item) => item.name)
                  .join(", ")
                  .slice(0, 50) + "..."
              : brineORCMonitoringStore.brineORCChart?.lefts.map((item) => item.name).join(", "),
        },
        reversed: true,
      },
      {
        title: {
          text:
            brineORCMonitoringStore.brineORCChart?.rights.map((item) => item.name).join(", ").length ?? 0 > 50
              ? brineORCMonitoringStore.brineORCChart?.rights
                  .map((item) => item.name)
                  .join(", ")
                  .slice(0, 50) + "..."
              : brineORCMonitoringStore.brineORCChart?.rights.map((item) => item.name).join(", "),
        },
        opposite: true,
        reversed: true,
      },
    ],
  };

  return (
    <Box sx={{ height: "100%" }}>
      {brineORCMonitoringStore.loadingChart ? (
        <Box sx={{ textAlign: "center" }}>
          <CircularProgress />
        </Box>
      ) : (
        <ReactApexChart
          height={400}
          type="scatter"
          options={options}
          series={[...(brineORCMonitoringStore.brineORCChart?.lefts ?? []), ...(brineORCMonitoringStore.brineORCChart?.rights ?? [])]}
        />
      )}
    </Box>
  );
}

export default observer(BrineORCChart);
