import { Box } from "@mui/system";
import React, { useCallback, useEffect, useState } from "react";
import { GridColDef } from "@mui/x-data-grid-premium";
import { GridValueFormatterParams } from "@mui/x-data-grid";
import { format } from "date-fns";
import { DecimationSegmentSummaryTable, DecimationSegmentTable } from "../../../../app/models/decimation";
import { useStore } from "../../../../app/stores/store";
import { StyledDataGridPremium } from "../../../../app/component/StyledDataGridPremium";

function PTDecimationViewStep1() {
  const { decimationStore } = useStore();
  const [decimationTable, setDecimationTable] = useState(decimationStore.decimation.table);
  const [decimationSegmentSummaryTable, setDecimationSegmentSummaryTable] = useState<Array<DecimationSegmentSummaryTable>>([]);

  useEffect(() => {
    if (decimationStore.decimation.table.length > 0) {
      updateSummarySegment(decimationTable.filter((x) => x.segment !== null));
      setDecimationTable(decimationStore.decimation.table);
    } else {
      setDecimationTable([]);
      setDecimationSegmentSummaryTable([]);
    }
  }, [decimationStore.decimation.table, decimationTable]);

  const gridColumns: GridColDef[] = [
    // { field: 'id', headerName: 'ID', width: 300, type: "string" },
    { flex: 1, field: "segmentStatus", headerName: "Segment Stat", align: "center", headerAlign: "center" },
    { flex: 1, field: "sequence", headerName: "Row", type: "number", align: "center", headerAlign: "center" },
    { flex: 1, field: "segment", headerName: "Segment #", type: "number", align: "center", headerAlign: "center" },
    {
      flex: 1,
      field: "time",
      headerName: "Time",
      type: "dateTime",
      align: "center",
      headerAlign: "center",
      valueFormatter: (params: GridValueFormatterParams) => {
        return format(new Date(params.value), "HH:mm:ss");
      },
    },
    { flex: 1, field: "depth", headerName: "Depth", type: "number", align: "center", headerAlign: "center" },
    { flex: 1, field: "speed", headerName: "Speed", type: "number", align: "center", headerAlign: "center" },
    { flex: 1, field: "pressure", headerName: "Pressure", type: "number", align: "center", headerAlign: "center" },
    { flex: 1, field: "temperature", headerName: "Temperature", type: "number", align: "center", headerAlign: "center" },
  ];

  const gridSummaryColumns: GridColDef[] = [
    // { field: 'id', headerName: 'ID', width: 300, type: "string" },
    { align: "center", headerAlign: "center", flex: 1, field: "segment", headerName: "Segment #", type: "number" },
    { align: "center", headerAlign: "center", flex: 1, field: "begin", headerName: "Begin", type: "number" },
    { align: "center", headerAlign: "center", flex: 1, field: "end", headerName: "End", type: "number" },
    { align: "center", headerAlign: "center", flex: 1, field: "type", headerName: "Type", type: "string" },
  ];

  const processRowUpdate = useCallback(
    async (newRow: any) => {
      const updatedRow = { ...newRow, isNew: false };
      // setDecimationTable(decimationTable.map((row) => (row.sequence === newRow.sequence ? updatedRow : row)));
      var checkDecimation;
      if (newRow.segment !== null) {
        checkDecimation = decimationTable.find((x) => x.segment === newRow.segment);
      }
      if (checkDecimation === undefined || checkDecimation?.segmentStatus === newRow.segmentStatus) {
        for (let i = newRow.sequence - 1; i < decimationTable.length; i++) {
          if (decimationTable[i].segmentStatus === newRow.segmentStatus) {
            const updateDecimation = decimationTable[i];
            updateDecimation.sequence = decimationTable[i].sequence;
            if (updateDecimation.segment === null || updateDecimation.sequence === newRow.sequence) {
              updateDecimation.segment = newRow.segment;
            }
            updateDecimation.segmentStatus = decimationTable[i].segmentStatus;
            updateDecimation.time = decimationTable[i].time;
            updateDecimation.depth = decimationTable[i].depth;
            updateDecimation.speed = decimationTable[i].speed;
            updateDecimation.pressure = decimationTable[i].pressure;
            updateDecimation.temperature = decimationTable[i].temperature;
            setDecimationTable(decimationTable.map((row) => (row.sequence === decimationTable[i].sequence ? updateDecimation : row)));
            decimationStore.setDecimationSegmentTable(
              decimationTable.map((row) => (row.sequence === decimationTable[i].sequence ? updateDecimation : row))
            );
          } else {
            break;
          }
        }
      } else {
        updatedRow.segment = null;
        setDecimationTable(decimationTable.map((row) => (row.sequence === updatedRow.sequence ? updatedRow : row)));
        decimationStore.setDecimationSegmentTable(decimationTable.map((row) => (row.sequence === updatedRow.sequence ? updatedRow : row)));
        alert("There is a segment " + checkDecimation?.segment + " in segment status " + checkDecimation?.segmentStatus);
      }
      return updatedRow;
    },
    [decimationTable, decimationStore]
  );

  function updateSummarySegment(array: Array<DecimationSegmentTable>) {
    const dataExport: Array<DecimationSegmentSummaryTable> = [];
    let currentSegment = array[0];
    let currentBegin = array[0]?.sequence;
    let id = 1;

    for (let i = 1; i < array.length; i++) {
      if (array[i].segmentStatus !== currentSegment.segmentStatus || array[i].segment !== currentSegment.segment) {
        const newSummary = new DecimationSegmentSummaryTable();
        newSummary.id = id;
        newSummary.segment = currentSegment.segment;
        newSummary.begin = currentBegin;
        newSummary.end = array[i - 1].sequence;
        newSummary.type = currentSegment.segmentStatus;
        dataExport.push(newSummary);
        currentSegment = array[i];
        currentBegin = array[i].sequence;
        id++;
      }
    }

    const newSummary = new DecimationSegmentSummaryTable();
    newSummary.id = id;
    newSummary.segment = currentSegment?.segment;
    newSummary.begin = currentBegin;
    newSummary.end = array[array.length - 1]?.sequence;
    newSummary.type = currentSegment?.segmentStatus;
    dataExport.push(newSummary);

    setDecimationSegmentSummaryTable(dataExport);
  }

  return (
    <Box>
      <StyledDataGridPremium
        disableColumnMenu
        columns={gridColumns}
        rows={decimationTable}
        getRowId={(row) => row.sequence}
        autoHeight
        pagination
        showColumnVerticalBorder
        showCellVerticalBorder
        processRowUpdate={processRowUpdate}
      />
      <br />
      <b>Summary Segment</b>
      <StyledDataGridPremium
        sx={{ mt: "6px" }}
        pagination
        disableColumnMenu
        showColumnVerticalBorder
        showCellVerticalBorder
        columns={gridSummaryColumns}
        rows={decimationSegmentSummaryTable}
        getRowId={(row) => row.id}
        autoHeight
      />
    </Box>
  );
}

export default PTDecimationViewStep1;
