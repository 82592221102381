import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useStore } from "../../../app/stores/store";
import RkdCard from "../../../app/component/card/RkdCard";
import {
  Autocomplete,
  Box,
  Checkbox,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import { MenuProps } from "../../geological/new/GeologicalPageCreate";
import { TBrineORCChartLeftRightYAxis, TGeneralTextValue } from "../../../app/models/geochemistry";
import BrineORCChart from "./chart/BrineORCChart";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { format } from "date-fns";
import BrineORCReportDataGrid from "./BrineORCReportDataGrid";

function BrineORCReport() {
  const { commonStore, brineORCMonitoringStore, orcGroupGridStore } = useStore();
  const [date, setDate] = useState<Date[]>([new Date(), new Date()]);
  const [selectedOrcGroup, setSelectedOrcGroup] = useState<TGeneralTextValue>({ text: "", value: "" });
  const [selectedMultipleLeftYAxis, setSelectedMultipleLeftYAxis] = useState<string[]>([]);
  const [displaySelectedLeftYAxis, setDisplaySelectedLeftYAxis] = useState<string[]>([]);
  const [selectedMultipleRightYAxis, setSelectedMultipleRightYAxis] = useState<string[]>([]);
  const [displaySelectedRightYAxis, setDisplaySelectedRightYAxis] = useState<string[]>([]);

  const onChangeDate = (newDate: Date, identifier: string) => {
    let temp = [...date];

    if (identifier === "startDate") temp[0] = newDate;
    if (identifier === "endDate") temp[1] = newDate;

    setDate(temp);
  };

  const getRightYAxis = async (leftYAxis: string[]) => {
    let payload = {
      leftYAxisValues: leftYAxis,
    };
    brineORCMonitoringStore.getBrineORCChartRightYAxis(payload).then((res: any) => {
      let result: string[] = [];
      let temp: string[] = [...selectedMultipleRightYAxis];
      res.forEach((val: TBrineORCChartLeftRightYAxis) => temp.includes(val.value) && result.push(val.text));
      setDisplaySelectedRightYAxis(result);
      setSelectedMultipleRightYAxis(temp);
    });
  };

  const handleSelectedORCGroup = (val: TGeneralTextValue) => {
    setSelectedOrcGroup(val);
  };

  const isAllSelectedYAxis =
    brineORCMonitoringStore.brineORCChartLeftYAxis.length > 0 &&
    selectedMultipleLeftYAxis.length === brineORCMonitoringStore.brineORCChartLeftYAxis.length;

  const isAllSelectedXAxis =
    brineORCMonitoringStore.brineORCChartRightYAxis.length > 0 &&
    selectedMultipleRightYAxis.length === brineORCMonitoringStore.brineORCChartRightYAxis.length;

  const handleSelectYAxis = async (event: SelectChangeEvent<typeof selectedMultipleLeftYAxis>, identifier: string) => {
    const { value } = event.target;
    let result: string[] = [];

    if (identifier === "right") {
      if (value[value.length - 1] === "all") {
        let tempTextDisplay = brineORCMonitoringStore.brineORCChartRightYAxis.map((item) => item.text);

        setSelectedMultipleRightYAxis(
          selectedMultipleRightYAxis.length === brineORCMonitoringStore.brineORCChartRightYAxis.length
            ? []
            : brineORCMonitoringStore.brineORCChartRightYAxis.map((item) => item.value)
        );
        setDisplaySelectedRightYAxis(tempTextDisplay);
        return;
      }

      brineORCMonitoringStore.brineORCChartRightYAxis.forEach((val) => value.includes(val.value) && result.push(val.text));
      setDisplaySelectedRightYAxis(result);
      setSelectedMultipleRightYAxis(typeof value === "string" ? value.split(",") : value);
    } else {
      if (value[value.length - 1] === "all") {
        let tempTextDisplay = brineORCMonitoringStore.brineORCChartLeftYAxis.map((item) => item.text);

        setSelectedMultipleLeftYAxis(
          selectedMultipleLeftYAxis.length === brineORCMonitoringStore.brineORCChartLeftYAxis.length
            ? []
            : brineORCMonitoringStore.brineORCChartLeftYAxis.map((item) => item.value)
        );
        setDisplaySelectedLeftYAxis(tempTextDisplay);
        getRightYAxis(brineORCMonitoringStore.brineORCChartLeftYAxis.map((item) => item.value));
        return;
      }

      brineORCMonitoringStore.brineORCChartLeftYAxis.forEach((val) => value.includes(val.value) && result.push(val.text));
      setDisplaySelectedLeftYAxis(result);
      setSelectedMultipleLeftYAxis(typeof value === "string" ? value.split(",") : value);
      console.log("🚀 ~ handleSelectYAxis ~ value:", value);
      if (value.length > 0) {
        getRightYAxis(typeof value === "string" ? value.split(",") : value);
      }
    }
  };

  useEffect(() => {
    commonStore.setTitlePage("Brine ORC Report");
    brineORCMonitoringStore.getBrineORCChartLeftYAxis();
    orcGroupGridStore.getOrcGroupOptions();
  }, []);

  useEffect(() => {
    if (!selectedOrcGroup.value) return;
    let tempStartDate = format(new Date(date[0]), "yyyy-MM-dd");
    let tempEndDate = format(new Date(date[1]), "yyyy-MM-dd");

    let payload = {
      startDate: tempStartDate,
      endDate: tempEndDate,
      orcGroup: selectedOrcGroup,
      leftYAxisValues: selectedMultipleLeftYAxis.map((item, index) => ({ value: item, text: displaySelectedLeftYAxis[index] })),
      rightYAxisValues: selectedMultipleRightYAxis.map((item, index) => ({ value: item, text: displaySelectedRightYAxis[index] })),
    };

    brineORCMonitoringStore.getBrineORCReportGrid(selectedOrcGroup.value, tempStartDate, tempEndDate);
    brineORCMonitoringStore.getBrineORCChart(payload);
  }, [
    brineORCMonitoringStore,
    date,
    displaySelectedLeftYAxis,
    displaySelectedRightYAxis,
    selectedMultipleLeftYAxis,
    selectedMultipleRightYAxis,
    selectedOrcGroup,
  ]);

  return (
    <Grid container>
      <Grid item xs={12}>
        <RkdCard title="Brine ORC Report" subtitle="Brine ORC Report View">
          <Grid container>
            <Grid item xs={4} sx={{ display: "flex", gap: "6px" }}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="Start Date"
                  value={date[0]}
                  inputFormat="dd MMM yyyy"
                  onChange={(e: any) => onChangeDate(e, "startDate")}
                  renderInput={(props) => <TextField required fullWidth name="startDate" {...props} />}
                />
              </LocalizationProvider>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="End Date"
                  value={date[1]}
                  inputFormat="dd MMM yyyy"
                  onChange={(e: any) => onChangeDate(e, "endDate")}
                  renderInput={(props) => <TextField required fullWidth name="endDate" {...props} />}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={8} sx={{ display: "flex", justifyContent: "flex-end", gap: "6px" }}>
              <Autocomplete
                disablePortal
                fullWidth
                sx={{ marginLeft: "6px" }}
                onChange={(e: any, val: any) => handleSelectedORCGroup(val)}
                disableClearable
                value={selectedOrcGroup}
                getOptionLabel={(option) => option.text}
                options={orcGroupGridStore.orcGroupOptions.map((item) => ({ text: item.text, value: item.value }))}
                renderInput={(params) => <TextField value={selectedOrcGroup?.text} name="orcGroup" {...params} label="ORC Group" />}
              />
              <FormControl sx={{ width: "50%", justifyContent: "flex-end" }}>
                <InputLabel id="demo-multiple-checkbox-label">Left YAxes</InputLabel>
                <Select
                  multiple
                  value={selectedMultipleLeftYAxis}
                  onChange={(e) => handleSelectYAxis(e, "left")}
                  input={<OutlinedInput label="Left YAxes" />}
                  renderValue={() => displaySelectedLeftYAxis.join(", ")}
                  MenuProps={MenuProps}
                >
                  <MenuItem value="all">
                    <Checkbox
                      checked={isAllSelectedYAxis}
                      indeterminate={
                        selectedMultipleLeftYAxis.length > 0 &&
                        selectedMultipleLeftYAxis.length < brineORCMonitoringStore.brineORCChartLeftYAxis.length
                      }
                    />
                    <ListItemText primary="Select All" />
                  </MenuItem>
                  {brineORCMonitoringStore.brineORCChartLeftYAxis.map((item, index) => (
                    <MenuItem key={index} value={item.value}>
                      <Checkbox checked={selectedMultipleLeftYAxis.indexOf(item.value) > -1} />
                      <ListItemText primary={item.text} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl sx={{ width: "50%", justifyContent: "flex-end" }}>
                <InputLabel id="demo-multiple-checkbox-label">Right YAxes</InputLabel>
                <Select
                  multiple
                  value={selectedMultipleRightYAxis}
                  onChange={(e) => handleSelectYAxis(e, "right")}
                  input={<OutlinedInput label="Right YAxes" />}
                  renderValue={() => displaySelectedRightYAxis.join(", ")}
                  MenuProps={MenuProps}
                >
                  <MenuItem value="all">
                    <Checkbox
                      checked={isAllSelectedXAxis}
                      indeterminate={
                        selectedMultipleRightYAxis.length > 0 &&
                        selectedMultipleRightYAxis.length < brineORCMonitoringStore.brineORCChartRightYAxis.length
                      }
                    />
                    <ListItemText primary="Select All" />
                  </MenuItem>
                  {brineORCMonitoringStore.brineORCChartRightYAxis.map((item, index) => (
                    <MenuItem key={index} value={item.value}>
                      <Checkbox checked={selectedMultipleRightYAxis.indexOf(item.value) > -1} />
                      <ListItemText primary={item.text} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Divider sx={{ my: "6px" }} />
          <Typography variant="h2" sx={{ textAlign: "center" }}>
            Grid & Chart Reports
          </Typography>
          <Box sx={{ mt: "24px" }}>
            <BrineORCReportDataGrid />
          </Box>
          <Box sx={{ mt: "24px" }}>
            <BrineORCChart />
          </Box>
        </RkdCard>
      </Grid>
    </Grid>
  );
}

export default observer(BrineORCReport);
