import { GridValidRowModel, GridColDef } from "@mui/x-data-grid-premium";
import { StyledDataGridPremium } from "../../../../app/component/StyledDataGridPremium";
import { capitalizeFirstLetter } from "../../../../app/config/enum";
import { FormikValues } from "formik";
import { useEffect } from "react";
import { generateStep3GridData } from "../utils/utils";
import { Box } from "@mui/material";

type TStep3Grid = {
  selectionPLabel?: string;
  selectionTLabel?: string;
  values: FormikValues;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
};

export default function Step3Grid({ values, selectionPLabel = "", selectionTLabel = "", setFieldValue }: TStep3Grid) {
  const gridColumns: GridColDef<GridValidRowModel>[] = [
    {
      field: "depthOrElevation",
      headerName: values.step3?.zAxis ? capitalizeFirstLetter(values.step3?.zAxis) : "Z Axis",
      editable: true,
      align: "center",
      headerAlign: "center",
      flex: 1,
    },
    {
      field: "valueSelectionP",
      align: "center",
      headerName: `Selection P ${capitalizeFirstLetter(selectionPLabel)}`,
      headerAlign: "center",
      flex: 1,
    },
    {
      field: "valueSelectionT",
      align: "center",
      headerName: `Selection T ${capitalizeFirstLetter(selectionTLabel)}`,
      headerAlign: "center",
      flex: 1,
    },
    {
      field: "tsat",
      headerName: "Tsat (P)",
      align: "center",
      headerAlign: "center",
      editable: true,
      flex: 1.5,
      renderCell: (params) => (params.row.tsat === 0 ? <Box sx={{ color: "red" }}>Pressure diluar rentang value</Box> : params.row.tsat),
    },
    {
      field: "deltaTsat",
      headerName: "Delta Tsat (P)",
      align: "center",
      headerAlign: "center",
      editable: true,
      flex: 1,
    },
    {
      field: "thermodynamicState",
      headerName: "Thermodynamic State",
      align: "center",
      headerAlign: "center",
      editable: true,
      flex: 1,
    },
  ];

  useEffect(() => {
    if (!values.step3?.zAxis || !values.step3?.selectionP) return;

    const temp = generateStep3GridData(
      values.step3?.zAxis,
      values.step3?.selectionP,
      values.step3?.selectionT,
      JSON.parse(JSON.stringify([...values.step1?.correctionMeasureds])),
      values.step1?.correctionReferences
    );

    setFieldValue("step3.details", temp);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    values.step3?.zAxis,
    values.step3?.selectionP,
    values.step3?.selectionT,
    values.step1?.correctionMeasureds,
    values.step1?.correctionReferences,
  ]);

  return (
    <StyledDataGridPremium
      disableRowSelectionOnClick
      pagination
      getRowId={(row) => row.sequence}
      columns={gridColumns}
      rows={values.step3?.details ?? []}
      autoHeight
    />
  );
}
