import { DecimationSegmentTable } from "../models/decimation";
import { TCleanOoutHistoryGridDataResponse } from "../models/geochemistry";
import {
  GeoAlterTableRequest,
  GeoDrillParamTableRequest,
  GeoFeedZoneTableRequest,
  GeoLithologyTableRequest,
  GeoLossTableRequest,
  GeoPcsTableRequest,
  GeoTempTableRequest,
  TGeologicalBarDataResponse,
  TGeologicalScatterDataResponse,
} from "../models/geological";
import { TQuarterlyTablePayload } from "../models/quarterly";
import { GeologicalType } from "./enum";

export const getGeologicalType = (geologicalType: string) => {
  let type = 0;
  let geologicalName = "";

  switch (geologicalType) {
    case GeologicalType.LITHOLOGY:
      type = 1;
      geologicalName = "lithology";
      break;
    case GeologicalType.ALTERATION:
      geologicalName = GeologicalType.ALTERATION;
      type = 2;
      break;
    case GeologicalType.MEB:
      geologicalName = "meb";
      type = 3;
      break;
    case GeologicalType.DRILLPARAMETER:
      geologicalName = "drill-parameter";
      type = 4;
      break;
    case "DRILLPARAMETER":
      geologicalName = "drill-parameter";
      type = 4;
      break;
    case GeologicalType.PCS:
      geologicalName = GeologicalType.PCS;
      type = 5;
      break;
    case GeologicalType.LOSS:
      geologicalName = GeologicalType.LOSS;
      type = 6;
      break;
    case GeologicalType.FEEDZONE:
      geologicalName = "feed-zone";
      type = 7;
      break;
    case GeologicalType.TEMPERATURE:
      geologicalName = GeologicalType.TEMPERATURE;
      type = 8;
      break;
    case GeologicalType.TRAJECTORY:
      geologicalName = GeologicalType.TRAJECTORY;
      type = 9;
      break;
  }

  return { type, geologicalName };
};

export const dataConverterGeological = (dynamicConvertedData: any, dataParse: any, selectedGeologicalType: string) => {
  let dataExport = [];
  let index = dynamicConvertedData.length + 1;

  switch (selectedGeologicalType) {
    case GeologicalType.LITHOLOGY:
      for (var i = 2; i < dataParse.length; i++) {
        if (dataParse[i][0] !== "") {
          const newLithology = new GeoLithologyTableRequest();

          newLithology.sequence = index;
          newLithology.from = dataParse[i][0];
          newLithology.to = dataParse[i][1];
          newLithology.lithology = dataParse[i][2];
          newLithology.state = 1;
          dataExport.push(newLithology);

          index++;
        } else {
          break;
        }
      }
      break;
    case GeologicalType.ALTERATION:
      for (var i = 2; i < dataParse.length; i++) {
        if (dataParse[i][0] !== "") {
          const newAlter = new GeoAlterTableRequest();
          newAlter.sequence = index;
          newAlter.from = dataParse[i][0];
          newAlter.to = dataParse[i][1];
          newAlter.formation = dataParse[i][2];
          newAlter.alteration = dataParse[i][3];
          newAlter.state = 1;
          dataExport.push(newAlter);

          index++;
        } else {
          break;
        }
      }
      break;
    case GeologicalType.DRILLPARAMETER:
      for (var i = 2; i < dataParse.length; i++) {
        if (dataParse[i][0] !== "") {
          const newDrillParam = new GeoDrillParamTableRequest();

          newDrillParam.sequence = index;
          newDrillParam.depth = dataParse[i][0];
          newDrillParam.rop = dataParse[i][1];
          newDrillParam.tvd = dataParse[i][2];
          newDrillParam.state = 1;
          dataExport.push(newDrillParam);

          index++;
        } else {
          break;
        }
      }
      break;
    case GeologicalType.PCS:
      for (var i = 2; i < dataParse.length; i++) {
        if (dataParse[i][0] !== "") {
          const newPcs = new GeoPcsTableRequest();

          newPcs.sequence = index;
          newPcs.well = dataParse[i][1];
          newPcs.mmd = dataParse[i][2];
          newPcs.mvd = dataParse[i][3];
          newPcs.mmsl = dataParse[i][4];
          newPcs.easting = dataParse[i][5];
          newPcs.northing = dataParse[i][6];
          newPcs.state = 1;
          dataExport.push(newPcs);

          index++;
        } else {
          break;
        }
      }
      break;
    case GeologicalType.LOSS:
      for (var i = 2; i < dataParse.length; i++) {
        if (dataParse[i][0] !== "") {
          const newLoss = new GeoLossTableRequest();

          newLoss.sequence = index;
          newLoss.from = dataParse[i][0];
          newLoss.to = dataParse[i][1];
          newLoss.loss = dataParse[i][2];
          newLoss.state = 1;
          dataExport.push(newLoss);

          index++;
        } else {
          break;
        }
      }
      break;
    case GeologicalType.FEEDZONE:
      for (var i = 2; i < dataParse.length; i++) {
        if (dataParse[i][0] !== "") {
          const newFeedZone = new GeoFeedZoneTableRequest();

          newFeedZone.sequence = index;
          newFeedZone.feedZone = dataParse[i][0];
          newFeedZone.feedZoneDepth = dataParse[i][1];
          newFeedZone.mvd = dataParse[i][2];
          newFeedZone.masl = dataParse[i][3];
          newFeedZone.eastern = dataParse[i][4];
          newFeedZone.northern = dataParse[i][5];
          newFeedZone.state = 1;
          dataExport.push(newFeedZone);

          index++;
        } else {
          break;
        }
      }
      break;
    case GeologicalType.TEMPERATURE:
      for (var i = 2; i < dataParse.length; i++) {
        if (dataParse[i][0] !== "") {
          const newTemp = new GeoTempTableRequest();

          newTemp.sequence = index;
          newTemp.depth = dataParse[i][0];
          newTemp.tempIn = dataParse[i][1];
          newTemp.tempOut = dataParse[i][2];
          newTemp.state = 1;
          dataExport.push(newTemp);

          index++;
        } else {
          break;
        }
      }
      break;
  }

  return dataExport;
};

export const editRowQuarterly = (row: TQuarterlyTablePayload) => {
  let result = {
    ...row,
    pBara: row.pBarg + 101325 * (1 - 2.2557 * Math.pow(10, -5) * row.elevation) ** 5.25588 * 0.00000986923,
    flash: row.sRthr / (row.bRthr / +row.sRthr),
    whpBara: row.whpBarg + 101325 * (1 - 2.2557 * Math.pow(10, -5) * row.elevation) ** 5.25588 * 0.00000986923,
    linePBara: row.linePBarg + 101325 * (1 - 2.2557 * Math.pow(10, -5) * row.elevation) ** 5.25588 * 0.00000986923,
    pSampMeasuredBara: row.pSampMeasuredBarg + 101325 * (1 - 2.2557 * Math.pow(10, -5) * row.elevation) ** 5.25588 * 0.00000986923,
    latentHeatVapourisation: row.hSteam + row.hLiquid,
    ySurface: (row.htdCalc - row.hLiquid) / row.latentHeatVapourisation,
    ncgWt: row.ncgWt * 10,
    corrLI: row.li * (1 - row.ySurface),
    corrNa: row.na * (1 - row.ySurface),
    corrK: row.k * (1 - row.ySurface),
    corrCa: row.ca * (1 - row.ySurface),
    corrMg: row.mg * (1 - row.ySurface),
    corrMg1000: row.mg * (1 - row.ySurface) * 1000,
    corrSIO2: row.siO2 * (1 - row.ySurface),
    corrB: row.b * (1 - row.ySurface),
    corrCI: row.ci * (1 - row.ySurface),
    corrF: row.f * (1 - row.ySurface),
    corrSO4: row.sO4 * (1 - row.ySurface),
    corrHCO3: row.hcO3 * (1 - row.ySurface),
    corrCO3: row.cO3 * (1 - row.ySurface),
    corrNH4: row.nH4 * (1 - row.ySurface),
    corrAs: row.as * (1 - row.ySurface),
    corrSr: row.sr * (1 - row.ySurface),
    corrBa: row.ba * (1 - row.ySurface),
    corrFe: row.fe * (1 - row.ySurface),
    corrMn: row.mn * (1 - row.ySurface),
    corrNH3: row.nH3 * (1 - row.ySurface),
    corrAl: row.al * (1 - row.ySurface),
    corrSb: row.sb * (1 - row.ySurface),
    corrPb: row.pb * (1 - row.ySurface),
    corrZn: row.zn * (1 - row.ySurface),
    corrCu: row.cu * (1 - row.ySurface),
    corrCr: row.cr * (1 - row.ySurface),
    corrH2S: row.h2S * (1 - row.ySurface),
    corrNcgPpmw: row.ncgPpmw * row.ySurface,
    corrH2OPpmw: row.h2OPpmw * row.ySurface,
    corrCO2Ppmw: row.cO2Ppmw * row.ySurface,
    corrH2SPpmw: row.h2SPpmw * row.ySurface,
    corrNH3Ppmw: row.nH3Ppmw * row.ySurface,
    corrArPpmw: row.arPpmw * row.ySurface,
    corrNPpmw: row.cH4Ppmw * row.ySurface,
    corrCH4Ppmw: row.cH4Ppmw * row.ySurface,
    corrHPpmw: row.hPpmw * row.ySurface,
  };

  return result;
};

export const monthsList = ["Januari", "Februari", "Maret", "April", "Mei", "Juni", "Juli", "Agustus", "September", "Oktober", "November", "Desember"];

export const convertDataGridCleanOutHistory = (data: TCleanOoutHistoryGridDataResponse[]) => {
  let helper: TCleanOoutHistoryGridDataResponse[] = [...data];
  let tempColumnHelper: any[] = [];
  let tempGroupingHelper: any[] = [];
  let tempGroupingNewData: any[] = [];

  helper.map((item) => {
    let newYear: {
      [key: string]: any;
    } = {};

    item.months.map((itemData: any) => {
      newYear[`${itemData.monthDescription}${itemData.weekNumber}`] = itemData.status;
      newYear[`${itemData.monthDescription}${itemData.weekNumber}startDate`] = itemData.startDate;
      newYear[`${itemData.monthDescription}${itemData.weekNumber}endDate`] = itemData.endDate;
      newYear[`${itemData.monthDescription}${itemData.weekNumber}weekNumber`] = itemData.weekNumber;
      newYear[`${itemData.monthDescription}${itemData.weekNumber}month`] = itemData.month;
    });

    return tempGroupingNewData.push({ ...item, ...newYear });
  });

  helper[0]?.months.map((item: any) => {
    tempColumnHelper.push({
      field: `${item.monthDescription}${item.weekNumber}`,
      headerName: `Week ${item.weekNumber}`,
      sortable: false,
      align: "center",
      renderCell: () => <></>,
      headerAlign: "center",
      headerClassName: "",
      disableColumnMenu: true,
      width: 100,
    });
  });

  monthsList.map((item: string, index) => {
    tempGroupingHelper.push({
      groupId: item.toLowerCase(),
      headerName: item,
      headerAlign: "center",
      showCellRightBorder: true,
      headerClassName: "",
      children: [{ field: `${item}1` }, { field: `${item}2` }, { field: `${item}3` }, { field: `${item}4` }, { field: `${item}5` }],
    });
  });

  return { tempColumnHelper, tempGroupingHelper, tempGroupingNewData };
};

export const convertBarAndScatterData = (barsData: TGeologicalBarDataResponse[], scattersData: TGeologicalScatterDataResponse[]) => {
  const barsDummyDataHelper = {} as any;
  barsData.map((item) => (barsDummyDataHelper[item.name] = item.value));

  const scattersDummyDataHelper = {} as any;
  scattersData.map((item) => (scattersDummyDataHelper[item.type] = item.depth));

  const mergedData = { ...barsDummyDataHelper, ...scattersDummyDataHelper };
  const maxData = Math.max(...Object.values(mergedData as number));

  return { ...mergedData, mebY: maxData };
};

export const mergeAndInterpolateData = (data: DecimationSegmentTable[], selectedSegment: number) => {
  let allData = [...data].filter((x) => x.segment !== null);
  let existingData = [...data].filter((x) => x.segment === selectedSegment);
  let maxExistingData = [...existingData].reduce((acc, current) => Math.max(acc, current.depth), existingData[0].depth);
  let minExistingData = [...existingData].reduce((acc, current) => Math.min(acc, current.depth), existingData[0].depth);
  let mergedData = [...allData].filter((item) => item.depth >= minExistingData && item.depth <= maxExistingData).sort((a, b) => a.depth - b.depth);

  let result = [...mergedData].map((item, index) => {
    if (existingData.some((existing) => item.depth === existing.depth)) {
      return item;
    } else {
      let interpolatePressure =
        mergedData[index - 1].pressure +
        ((item.depth - mergedData[index - 1].depth) * (mergedData[index + 1].pressure - mergedData[index - 1].pressure)) /
          (mergedData[index + 1].depth - mergedData[index - 1].depth);
      let interpolateTemperature =
        mergedData[index - 1].temperature +
        ((item.depth - mergedData[index - 1].depth) * (mergedData[index + 1].temperature - mergedData[index - 1].temperature)) /
          (mergedData[index + 1].depth - mergedData[index - 1].depth);
      return { ...item, pressure: interpolatePressure, temperature: interpolateTemperature };
    }
  });

  return result;
};

export const interpolateData = (data: DecimationSegmentTable[], depth: number, index: number, identifier: string) => {
  let key = identifier as keyof DecimationSegmentTable;

  if (index === 0) {
    return;
  }

  let result =
    Number(data[index - 1][key]) +
    ((Number(depth) - data[index - 1].depth) * (Number(data[index + 1][key]) - Number(data[index - 1][key]))) /
      (data[index + 1].depth - data[index - 1].depth);

  return result;
};

export const convertBase64 = (file: any) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);

    fileReader.onload = () => {
      resolve(fileReader.result);
    };

    fileReader.onerror = (error) => {
      reject(error);
    };
  });
};

export const TFTPageProcessDisabledField = [
  {
    name: "samePort",
    label: "Same Port",
    type: "boolean",
  },
  {
    name: "pressDiffFlag",
    label: "Press Diff Flag",
    type: "boolean",
  },
  {
    name: "avgSteamPressure",
    label: "Avg Steam Pressure",
    type: "number",
  },
  {
    name: "avgBrinePressure",
    label: "Avg Brine Pressure",
    type: "number",
  },
  {
    name: "averageTestPressure",
    label: "Avg Test Pressure",
    type: "number",
  },
  {
    name: "steamEnthAvgP",
    label: "Steam Enth @ Avg P",
    type: "number",
  },
  {
    name: "brineEnthAvgP",
    label: "Brine Enth @ Avg P",
    type: "number",
  },
  {
    name: "steamFlowSteamPChoice",
    label: "Steam Flow @ Steam P Choice",
    type: "number",
  },
  {
    name: "tmfAvgP",
    label: "TMF @ Avg P",
    type: "number",
  },
  {
    name: "tmfAvgPNCGcorr",
    label: "TMF @ Avg P NCG Corr",
    type: "number",
  },
  {
    name: "steamFractionAvgP",
    label: "Steam Fraction @ Avg P",
    type: "number",
  },
  {
    name: "steamFractionAvgPNCGCorr",
    label: "Steam Fraction @ Avg P NCG Corr",
    type: "number",
  },
  {
    name: "steamFractionAvgPChoice",
    label: "Steam Fraction @ Avg P Choice",
    type: "number",
  },
  {
    name: "steamEnthSteamP",
    label: "Steam Enth @ Steam P",
    type: "number",
  },
  {
    name: "brineEnthSteamP",
    label: "Brine Enth @ Steam P",
    type: "number",
  },
  {
    name: "calcBrineSteamP",
    label: "Calc Brine @ Steam P",
    type: "number generated by formula",
  },
  {
    name: "calcBrineSteamPNcgCorr",
    label: "Calc Brine @ Steam P NCG Corr",
    type: "number",
  },
  {
    name: "tmfSteamP",
    label: "TMF @ Steam P",
    type: "number",
  },
  {
    name: "tmfSteamPNcgCorr",
    label: "TMF @ Steam P NCG Corr",
    type: "number",
  },
  {
    name: "steamFractionSteamP",
    label: "Steam Fraction @ Steam P",
    type: "number",
  },
  {
    name: "steamFractionAvgPNcgCorr",
    label: "Steam Fraction @ Steam P NCG Corr",
    type: "number",
  },
  {
    name: "steamFractionAvgPChoice",
    label: "Steam Fraction @ Steam P Choice",
    type: "number",
  },
  {
    name: "steamEnthBrineP",
    label: "Steam Enth @ Brine P",
    type: "number",
  },
  {
    name: "brineEnthBrineP",
    label: "Brine Enth @ Brine P",
    type: "number",
  },
  {
    name: "calcSteamBrineP",
    label: "calc Steam @ Brine P",
    type: "number",
  },
  {
    name: "calcSteamBrinePNcgCorr",
    label: "Calc Steam @ Brine P NCG Corr",
    type: "number",
  },
  {
    name: "steamFractionBrineP",
    label: "Steam Fraction @ Brine P",
    type: "number",
  },
  {
    name: "steamFractionBrinePNcgCorr",
    label: "Steam Fraction @ Brine P NCG Corr",
    type: "number",
  },
  {
    name: "steamFractionBrinePChoice",
    label: "Steam Fraction @ Brine P Choice",
    type: "number",
  },
  {
    name: "totalEnthAvgPress",
    label: "Total Enth @ Avg Pressure",
    type: "number",
  },
  {
    name: "totalEnthAvgPressureNCGcorr",
    label: "Total Enth @ Avg Pressure NCG Corr",
    type: "number",
  },
  {
    name: "totalEnthDiffPress",
    label: "Total Enth @ Diff Pressures",
    type: "number",
  },
  { name: "totalEnthDiffPressNcgCorr", label: "Total Enth @ Diff Pressures NCG Corr", type: "number" },
  { name: "steamEnthProdP", label: "Steam Enth @ Prod P", type: "number" },
  { name: "brineEnthProdP", label: "Brine Enth @ Prod P", type: "number" },
  { name: "calcSteamProdP", label: "Calc Steam @ Prod P", type: "number" },
  { name: "calcBrineProdP", label: "Calc Brine @ Prod P", type: "number" },
  { name: "tmfProductionP", label: "TMF @ Production P", type: "number" },
  {
    name: "steamFractionProdP",
    label: "Steam Fraction @ Prod P",
    type: "number",
  },
];
