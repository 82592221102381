import { observer } from "mobx-react-lite";
import ReactApexChart from "react-apexcharts";
import { useStore } from "../../../app/stores/store";
import { useEffect, useState } from "react";
import { IFPTChartResponse } from "../../../app/models/fpt";
import { Box, CircularProgress } from "@mui/material";
import { DecimationSegmentTable } from "../../../app/models/decimation";

type TStep3Chart = {
  decimated: DecimationSegmentTable[];
  undecimated: any;
};
function Step3Chart({ decimated, undecimated }: TStep3Chart) {
  const { decimationStore } = useStore();
  const [chartSeries, setChartSeries] = useState<IFPTChartResponse>();

  useEffect(() => {
    let payload = {
      decimateds: decimated.map((x) => ({ depth: x.depth, pressure: x.pressure, temperature: x.temperature })),
      undecimateds: undecimated.map((x: any) => ({ depth: x.depthDecimated, pressure: x.pressureDecimated, temperature: x.temperatureDecimated })),
    };

    if (decimated.length === 0 || undecimated.length === 0) return;

    decimationStore.getStep3DecimationChart(payload).then((res) => setChartSeries(res));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [decimated, undecimated]);

  const options: ApexCharts.ApexOptions = {
    chart: {
      height: "20px",
      width: "100%",
    },
    xaxis: {
      type: "numeric",
      // tickAmount: 8,
      // position: "top",
      labels: {
        show: true,
        rotate: -45,
        rotateAlways: false,
      },
    },
    markers: {
      size: 6,
    },
    yaxis: [
      {
        title: {
          text: "Temperature(degF)",
        },
        reversed: true,
      },
      {
        title: {
          text: "Pressure(psig)",
        },
        opposite: true,
        reversed: true,
      },
    ],
  };

  return (
    <Box sx={{ height: "100%" }}>
      {decimationStore.loadingChart ? (
        <Box sx={{ textAlign: "center" }}>
          <CircularProgress />
        </Box>
      ) : (
        <ReactApexChart height="400px" options={options} series={chartSeries?.charts ?? []} />
      )}
    </Box>
  );
}

export default observer(Step3Chart);
