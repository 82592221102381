import { useEffect } from "react";
import { GridColDef, GridValidRowModel } from "@mui/x-data-grid-premium";
import { GridActionsCellItem } from "@mui/x-data-grid";
import { useStore } from "../../../app/stores/store";
import { observer } from "mobx-react-lite";
import { Delete } from "@mui/icons-material";
import { StyledDataGridPremium } from "../../../app/component/StyledDataGridPremium";
import {
  QuarterlyTable,
  aRDisplay,
  cH4Display,
  cO2Display,
  editableRowData,
  h2SDisplay,
  hDisplay,
  mglDisplay,
  nDisplay,
  nH3Display,
  ncgDisplay,
  percentageDisplay,
} from "../../../app/config/enum";
import { format } from "date-fns";
import { useParams } from "react-router-dom";
import { TQuarterlyTablePayload } from "../../../app/models/quarterly";
import { editRowQuarterly } from "../../../app/config/utils";

function QuarterlyPageCreateDataGrid() {
  const { commonStore, quarterlyStore } = useStore();
  const {
    getQuarterlyGrid,
    quarterlyPageCreateGrid,
    editQuarterly,
    setQuarterlyPageCreateGrid,
    loadingGrid,
    deleteQuarterlyDetail,
    getQuarterlyDetail,
  } = quarterlyStore;
  const { wellId } = useParams();

  const deleteRow = (sequence: number) => {
    let data = quarterlyPageCreateGrid.filter((item) => item.sequence !== sequence);
    // data.forEach((x, index) => (x.sequence = index + 1));
    setQuarterlyPageCreateGrid(data);
  };

  const handleRowEdit = (row: any) => {
    const newData = editRowQuarterly(row);

    if (wellId) editQuarterly({ ...newData, id: row.quarterSampleId });

    const temp: TQuarterlyTablePayload[] = quarterlyPageCreateGrid.map((current) => {
      if (current.sequence !== row.sequence) return current;

      return newData;
    });

    setQuarterlyPageCreateGrid(temp);
    return newData;
  };

  const generateGridColumns = () => {
    let temp: GridColDef<GridValidRowModel>[] = [];
    Object.entries(QuarterlyTable).map(([key, value], index) =>
      temp.push({
        field: key,
        editable: editableRowData.includes(key) ? false : true,
        headerAlign: "center",
        align: "center",
        headerName: value,
        width: 120,
        renderCell: (params) => <>{key === "sampleDate" ? format(new Date(params.row.sampleDate), "dd MMM yyyy") : params.row[key]}</>,
      })
    );

    temp.push({
      field: "actions",
      headerName: "Action",
      type: "actions",
      getActions: (params: any) => [
        <GridActionsCellItem
          icon={<Delete />}
          color="info"
          label="Edit"
          onClick={() => {
            if (wellId) deleteQuarterlyDetail(commonStore.selectedDataOnGrid).then(() => getQuarterlyDetail(wellId));
            else deleteRow(params.row.sequence);
          }}
        />,
      ],
    });
    return temp;
  };

  const gridColumns: GridColDef<GridValidRowModel>[] = generateGridColumns();

  useEffect(() => {
    getQuarterlyGrid();
  }, [getQuarterlyGrid]);

  return (
    <StyledDataGridPremium
      getRowId={(row) => row.sampleDate}
      autoHeight
      showCellVerticalBorder
      showColumnVerticalBorder
      sx={{
        ".datagrid-disable": { background: "#EDEFF1" },
      }}
      getCellClassName={(params: any) => {
        if (editableRowData.includes(params.field)) return "datagrid-disable";

        return "";
      }}
      processRowUpdate={handleRowEdit}
      checkboxSelection
      onRowSelectionModelChange={(newSelectionModel: any) => {
        commonStore.setSelectionDataOnGrid(newSelectionModel);
      }}
      columnGroupingModel={[
        {
          groupId: "m",
          headerAlign: "center",
          children: [{ field: "elevation" }],
        },
        {
          groupId: "P",
          headerAlign: "center",
          children: [{ field: "pBarg" }, { field: "pBara" }],
        },
        {
          groupId: "WHP",
          headerAlign: "center",
          children: [{ field: "whpBarg" }, { field: "whpBara" }],
        },
        {
          groupId: "Line P",
          headerAlign: "center",
          children: [{ field: "linePBarg" }, { field: "linePBara" }],
        },
        {
          groupId: "P samp_Measured",
          headerAlign: "center",
          children: [{ field: "pSampMeasuredBarg" }, { field: "pSampMeasuredBara" }],
        },
        {
          groupId: "mg/l",
          headerAlign: "center",
          children: mglDisplay.map((item) => ({ field: item })),
        },
        {
          groupId: "CO2",
          headerAlign: "center",
          children: cO2Display.map((item) => ({ field: item })),
        },
        {
          groupId: "H2S",
          headerAlign: "center",
          children: h2SDisplay.map((item) => ({ field: item })),
        },
        {
          groupId: "NH3",
          headerAlign: "center",
          children: nH3Display.map((item) => ({ field: item })),
        },
        {
          groupId: "Ar",
          headerAlign: "center",
          children: aRDisplay.map((item) => ({ field: item })),
        },
        {
          groupId: "N",
          headerAlign: "center",
          children: nDisplay.map((item) => ({ field: item })),
        },
        {
          groupId: "CH4",
          headerAlign: "center",
          children: cH4Display.map((item) => ({ field: item })),
        },
        {
          groupId: "H",
          headerAlign: "center",
          children: hDisplay.map((item) => ({ field: item })),
        },
        { groupId: "NCG", headerAlign: "center", children: ncgDisplay.map((item) => ({ field: item })) },
        { groupId: "%", headerAlign: "center", children: percentageDisplay.map((item) => ({ field: item })) },
      ]}
      pagination
      pageSizeOptions={[10, 25, 50, 100]}
      loading={loadingGrid}
      columns={gridColumns}
      rows={quarterlyPageCreateGrid}
      experimentalFeatures={{ columnGrouping: true }}
      disableRowSelectionOnClick
      disableColumnSelector
    />
  );
}

export default observer(QuarterlyPageCreateDataGrid);
