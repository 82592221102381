import { observer } from "mobx-react-lite";
import { FptConnectedWellDetail, FptTestVesselDetail, FptTestWell, FptTestWellResult, VesselDetail } from "../../app/models/fpt";
import RkdCard from "../../app/component/card/RkdCard";
import { Box } from "@mui/system";
import FPTTestWellGrid from "./FPTTestWellGrid";
import { Button } from "@mui/material";
import { DataGridPremium, GridColDef } from "@mui/x-data-grid-premium";
import React, { useCallback, useState } from "react";
import { useStore } from "../../app/stores/store";
import FPTChart from "./FPTChart";
import FPTConnectedWellGrid from "./FPTConnectedWellGrid";
import { GridActionsCellItem } from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";

interface IProps {
    nameWell: string,
    testWells: Array<FptTestWell>,
    wells: Array<FptConnectedWellDetail>,
    vessel: VesselDetail,
    testWellsResult: Array<FptTestWellResult>,
}

function FPTGenerateForm(props: IProps){
    const { ftpStore } = useStore();
    const [ testVesselTable, setTestVesselTable] = useState(props.vessel.table);
    const [ testWellTable, setTestWellTable] = useState(props.testWellsResult);

    // useEffect(() => {
    //     setTestVesselTable(props.vessel.table);
    //     setTestWellTable(props.testWellsResult);
    // }, [props.testWellsResult, props.vessel.table]);

    const gridColumnsVessel: GridColDef[] = [
        { field: 'psep', headerName: 'psep (psig)', width: 90, type: "number", editable: true },
        { field: 'wkph', headerName: 'WS (kph)', width: 90, type: "number", editable: true },
        {
            field: "actions",
            headerName: "Action",
            type: "actions",
            width: 70,
            getActions: (params) => [
              <GridActionsCellItem icon={<DeleteIcon />} color="error" label="Delete" onClick={() => handleDeleteRowVessel(params.row.sequence)} />,
            ],
          },
    ]

    const handleDeleteRowVessel = (sequence: number) => {
        setTestVesselTable(testVesselTable.filter((row) => row.sequence !== sequence));
        ftpStore.setTestVesselTable(
            testVesselTable.filter((row) => row.sequence !== sequence),
        );
      };

    const gridColumnsTestWellResult: GridColDef[] = [
        { field: 'fcv', headerName: 'FCV (%)', width: 90, type: "number", editable: true },
        { field: 'whp', headerName: 'WHP (psg)', width: 90, type: "number", editable: true },
        { field: 'psep', headerName: 'psep (psig)', width: 90, type: "number", editable: true },
        { field: 'wkph', headerName: 'WS (kph)', width: 90, type: "number", editable: true },
        { field: 'remarks', headerName: 'Remarks', width: 90, type: "string", editable: true },
        {
            field: "actions",
            headerName: "Action",
            type: "actions",
            width: 70,
            getActions: (params) => [
              <GridActionsCellItem icon={<DeleteIcon />} color="error" label="Delete" onClick={() => handleDeleteRowTestWellResult(params.row.sequence)} />,
            ],
          },
    ]
   
    const handleDeleteRowTestWellResult = (sequence: number) => {
        setTestWellTable(testWellTable.filter((row) => row.sequence !== sequence));
        ftpStore.setTestWellTableResult(
            testWellTable.filter((row) => row.sequence !== sequence),
        );
      };

    const handleAddRowVessel = () => {
        let detail = new FptTestVesselDetail();
        detail.sequence = testVesselTable.length > 0 ? testVesselTable[testVesselTable.length - 1].sequence + 1 : 1;
        detail.psep = 0;
        detail.wkph = 0;
        ftpStore.addTestVesselTable(detail);
        setTestVesselTable((nextTestVessel:any) => [...nextTestVessel, detail]);
        // fetchChart(ftpStore.fpt);
    }

    const handleAddRowTestWellResult = () => {
        let detail = new FptTestWellResult();
        detail.sequence = testWellTable.length > 0 ? testWellTable[testWellTable.length - 1].sequence + 1 : 1;
        detail.fcv = 0;
        detail.whp = 0;
        detail.psep = 0;
        detail.wkph = 0;
        detail.remarks = "";
        ftpStore.addTestWellTableResult(detail);
        setTestWellTable((nextTestWell:any) => [...nextTestWell, detail]);
        // fetchChart(ftpStore.fpt);
    }

    const processRowUpdateVessel = useCallback(
        async(newRow:any) => {
            const updatedRow = { ...newRow, isNew: false };
            setTestVesselTable(testVesselTable.map((row) => (row.sequence === newRow.sequence ? updatedRow : row)));
            ftpStore.setTestVesselTable(testVesselTable.map((row) => (row.sequence === newRow.sequence ? updatedRow : row)));
            // fetchChart(ftpStore.fpt);
            return updatedRow;
        }, [ftpStore, testVesselTable]
    );

    const processRowUpdateTestWellResult = useCallback(
        async(newRow:any) => {
            const updatedRow = { ...newRow, isNew: false };
            setTestWellTable(testWellTable.map((row) => (row.sequence === newRow.sequence ? updatedRow : row)));
            ftpStore.setTestWellTableResult(testWellTable.map((row) => (row.sequence === newRow.sequence ? updatedRow : row)));
            // fetchChart(ftpStore.fpt);
            return updatedRow;
        }, [ftpStore, testWellTable]
    );

    return(
        <Box>
            <Box sx={{ mb: 2, display: 'flex', flexDirection: 'row', width: '100%', overflowX: "scroll", gap: 2, }}>
                <Box key={55} sx={{ display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'left', gap: 2 }}>
                    <RkdCard key={0} title='Test Well'>
                        <Box key={11} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'left', gap: 2 }}>
                            {props.testWells.map((element, key) => {
                                return(
                                    <FPTTestWellGrid key={key} fptTestWell={element}/>
                                );
                            })}
                        </Box>
                    </RkdCard>
                </Box>
                <Box key={11} sx={{ display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'left', gap: 2 }}>
                    <RkdCard key={1} title='Connected Well'>
                        <Box key={12} sx={{ display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', gap: 2 }}>
                            {props.wells.map((element, key) => {
                                return(
                                    <FPTConnectedWellGrid key={key} fptConnectedWellDetail={element}/>
                                );
                            })}
                        </Box>
                    </RkdCard>
                </Box>
                <Box key={56} sx={{ display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'left', gap: 2 }}>
                    <RkdCard key={2} title='Vessel'>
                        <Box key={11} sx={{ display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'left', gap: 2 }}>
                            <b>{props.vessel.vesselName}</b>
                            <Button variant='contained' color='primary' onClick={handleAddRowVessel} sx={{ width: '10%' }}>Add</Button>
                            <DataGridPremium
                                columns={gridColumnsVessel} 
                                rows={testVesselTable} 
                                getRowId={(row) => row.sequence} 
                                autoHeight 
                                getRowHeight={() => 100}
                                onProcessRowUpdateError={(e) => console.error(e)} 
                                processRowUpdate={processRowUpdateVessel}
                                // initialState={{
                                // sorting: {
                                //     sortModel: [{ field: 'sequence', sort: 'asc' }],
                                // },
                                // }}
                            />
                        </Box>
                    </RkdCard>
                </Box>
                <Box key={57} sx={{ display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'left', gap: 2 }}>
                    <RkdCard key={3} title='Test Well Result'>
                        <Box key={11} sx={{ display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'left', gap: 2 }}>
                            <b>{props.nameWell}</b>
                            <Button variant='contained' color='primary' onClick={handleAddRowTestWellResult} sx={{ width: '10%' }}>Add</Button>
                            <DataGridPremium
                                columns={gridColumnsTestWellResult} 
                                rows={testWellTable} 
                                getRowId={(row) => row.sequence} 
                                autoHeight 
                                getRowHeight={() => 100}
                                onProcessRowUpdateError={(e) => console.error(e)} 
                                processRowUpdate={processRowUpdateTestWellResult}
                                // initialState={{
                                // sorting: {
                                //     sortModel: [{ field: 'sequence', sort: 'asc' }],
                                // },
                                // }}
                            />
                        </Box>
                    </RkdCard>
                </Box>
            </Box>
            <Box>
                <FPTChart series={ftpStore.fptChart}/>
            </Box>
        </Box>
    );
}

export default observer(FPTGenerateForm);