import React, { useEffect, useMemo, useRef, useState } from "react";
import { Autocomplete, Box, CircularProgress, Grid, TextField } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useNavigate, useParams } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import { useStore } from "../../../app/stores/store";
import RkdCard from "../../../app/component/card/RkdCard";
import * as yup from "yup";
import { useFormik } from "formik";
import { IObjectOptions } from "../../../app/models/object";
import { DownloadOutlined, UploadOutlined } from "@mui/icons-material";
import QuarterlyPageCreateDataGrid from "./QuarterlyPageCreateDataGrid";
import { TSelectedData } from "../../pressureandtemp/decimation/PTDecimationForm";

const validationSchema = yup.object({
  wellId: yup.string().required(),
});

type ValidationSchema = yup.InferType<typeof validationSchema>;

function QuarterlyPageCreate() {
  const { cleanOutHistoryStore, orcGridStore, objectStore, quarterlyStore } = useStore();
  const { loadingDetail } = cleanOutHistoryStore;
  const [selectedWell, setSelectedWell] = useState<TSelectedData | undefined>(undefined);
  const { downloadTemplateQuarterly, quarterlyLoading, uploadTemplateQuarterly, addQuarterly, quarterlyPageCreateGrid, getQuarterlyDetail } =
    quarterlyStore;
  const [objectWellList, setObjectWellList] = useState<IObjectOptions[]>([]);
  const navigate = useNavigate();
  const { wellId } = useParams();
  const hiddenFileInput = useRef<any>(null);

  const initialValues: ValidationSchema = useMemo(() => {
    if (wellId) {
      return { wellId };
    }

    return {
      wellId: "",
    };
  }, []);

  const onSubmit = (values: ValidationSchema) => {
    let payload = {
      wellId: values.wellId,
      table: quarterlyPageCreateGrid,
    };

    wellId ? navigate("/geochemistry-quarterly") : addQuarterly(payload).then(() => navigate("/geochemistry-quarterly"));
  };

  const downloadTemplate = () => downloadTemplateQuarterly(values.wellId);

  const uploadTemplate = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const formData = new FormData();

      formData.append("wellId", values.wellId);
      formData.append("file", e.target.files[0]);

      uploadTemplateQuarterly(formData);
    }
    e.target.value = "";
  };

  const { handleSubmit, handleChange, values, setFieldValue } = useFormik({
    onSubmit,
    initialValues,
    validationSchema,
    enableReinitialize: true,
  });

  useEffect(() => {
    const fetchWellList = async () => {
      await objectStore.getObjectOptions("Well").then((res) => {
        setObjectWellList(res);
      });
    };

    fetchWellList();

    if (wellId) {
      getQuarterlyDetail(wellId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let temp = [...objectWellList].find((item) => String(item.value) === String(wellId));
    setSelectedWell({ label: temp?.text ?? "", value: temp?.value ?? "" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [objectWellList]);

  return (
    <RkdCard title="Quarterly" subtitle={wellId ? "Edit" : "Add"}>
      {loadingDetail || orcGridStore.orcGetOptionsLoading ? (
        <Box sx={{ textAlign: "center" }}>
          <CircularProgress />
        </Box>
      ) : (
        <Box>
          <Grid container spacing="6px">
            <Grid item xs={2}>
              {wellId ? (
                <TextField
                  label="Well"
                  InputLabelProps={{ shrink: true }}
                  disabled
                  name="wellId"
                  fullWidth
                  value={selectedWell?.label}
                  sx={{ marginBotom: 0 }}
                />
              ) : (
                <Autocomplete
                  disablePortal
                  fullWidth
                  sx={{ marginBotom: 0 }}
                  onChange={(e: any, val: any) => {
                    setSelectedWell(val);
                    setFieldValue("wellId", val.value);
                  }}
                  disableClearable
                  disabled={!!wellId}
                  value={selectedWell}
                  getOptionLabel={(option) => option.label}
                  options={objectWellList.map((item) => ({ label: item.text, value: item.value }))}
                  renderInput={(params) => <TextField value={selectedWell?.label} name="wellId" {...params} label="Well" />}
                />
              )}
            </Grid>
            <Grid item xs={10} sx={{ display: "flex", gap: 1, justifyContent: "flex-end" }}>
              <LoadingButton
                loading={quarterlyLoading}
                fullWidth
                disabled={!values.wellId}
                sx={{ height: "100%", width: "30%" }}
                component="label"
                variant="contained"
                startIcon={<DownloadOutlined />}
                onClick={() => downloadTemplate()}
              >
                Download Template
              </LoadingButton>
              <LoadingButton
                loading={quarterlyLoading}
                fullWidth
                sx={{ height: "100%", width: "30%" }}
                disabled={!values.wellId}
                component="label"
                variant="contained"
                startIcon={<UploadOutlined />}
              >
                Upload Template
                <input
                  type="file"
                  accept=".xl*"
                  ref={hiddenFileInput}
                  hidden
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => uploadTemplate(e)}
                />
              </LoadingButton>
            </Grid>
            <hr />
            <Grid item xs={12} sx={{ mt: "12px" }}>
              <Box sx={{ textAlign: "center", fontWeight: "bold", fontSize: "18px", mb: "12px" }}>Data Conversion</Box>
              <QuarterlyPageCreateDataGrid />
            </Grid>
          </Grid>
          <Box>
            <Box sx={{ mt: "24px", display: "flex", gap: "12px", justifyContent: "flex-end" }}>
              <Box>
                <LoadingButton disabled={quarterlyLoading} variant="contained" color="error" onClick={() => navigate("/geochemistry-quarterly")}>
                  Cancel
                </LoadingButton>
              </Box>
              <Box>
                <LoadingButton loading={quarterlyLoading} onClick={() => handleSubmit()} type="submit" variant="contained" color="primary">
                  Submit
                </LoadingButton>
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </RkdCard>
  );
}

export default observer(QuarterlyPageCreate);
