import React, { ChangeEvent, useEffect, useMemo, useState } from "react";
import * as yup from "yup";
import { useFormik } from "formik";
import { observer } from "mobx-react-lite";
import { useNavigate, useParams } from "react-router-dom";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { LoadingButton } from "@mui/lab";
import { Box, Button, CircularProgress, FormHelperText, Grid, MenuItem, TextField } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { UploadOutlined } from "@mui/icons-material";
import { useStore } from "../../../app/stores/store";
import RkdCard from "../../../app/component/card/RkdCard";
import { IObjectOptions } from "../../../app/models/object";
import { convertBase64 } from "../../../app/config/utils";

const validationSchema = yup.object({
  orcId: yup.string().required(),
  cleaningDate: yup.date(),
  mechanism: yup.string().required(),
  imageUrlBefore: yup.mixed().required(),
  imageUrlAfter: yup.mixed(),
  imageUrlSample: yup.mixed(),
  imageUrlXrd: yup.mixed(),
  imageBeforeBase64: yup.string(),
  imageAfterBase64: yup.string(),
  imageSampleBase64: yup.string(),
  imageXrdBase64: yup.string(),
});

const uploadInputHelper = ["imageUrlBefore", "imageUrlAfter", "imageUrlSample", "imageUrlXrd"];

type ValidationSchema = yup.InferType<typeof validationSchema>;

function CleanOutHistoryPageCreate() {
  const { biweeklyStore, cleanOutHistoryStore, orcGridStore } = useStore();
  const { cleanOutDetail, loadingDetail, addCleanOutDetail, getCleanOutDetail, editCleanOutDetail } = cleanOutHistoryStore;
  const [orcOptions, setOrcOptions] = useState<IObjectOptions[]>([]);
  const navigate = useNavigate();
  const { id } = useParams();

  const initialValues: ValidationSchema = useMemo(() => {
    if (id && cleanOutDetail) {
      let temp: {
        [key: string]: any;
      } = {};

      uploadInputHelper.map((item) => {
        if (!!eval("cleanOutDetail." + item)) {
          let imageDataHelper = new File([cleanOutDetail[item] as unknown as BlobPart], eval("cleanOutDetail." + item + ".fileName"), {
            type: cleanOutDetail.imageUrlBefore.contentType,
          });
          temp[item] = imageDataHelper;
        }
      });

      return {
        ...cleanOutDetail,
        imageUrlBefore: temp.imageUrlBefore,
        imageUrlAfter: temp.imageUrlAfter,
        imageUrlSample: temp.imageUrlSample,
        imageUrlXrd: temp.imageUrlXrd,
      };
    } else {
      return {
        orcId: "",
        cleaningDate: new Date(),
        mechanism: "",
        imageUrlBefore: undefined,
        imageUrlAfter: undefined,
        imageUrlSample: undefined,
        imageUrlXrd: undefined,
        imageBeforeBase64: "",
        imageAfterBase64: "",
        imageSampleBase64: "",
        imageXrdBase64: "",
      };
    }
  }, [cleanOutDetail]);

  const onSubmit = (values: ValidationSchema) => {
    const formData = new FormData();

    values.cleaningDate && formData.append("cleaningDate", values.cleaningDate.toLocaleString());
    values.orcId && formData.append("orcId", values.orcId);
    values.mechanism && formData.append("mechanism", values.mechanism);
    !!values.imageUrlBefore && formData.append("imageUrlBefore", values.imageUrlBefore as any);
    !!values.imageUrlAfter && formData.append("imageUrlAfter", values.imageUrlAfter as any);
    !!values.imageUrlSample && formData.append("imageUrlSample", values.imageUrlSample as any);
    !!values.imageUrlXrd && formData.append("imageUrlXrd", values.imageUrlXrd as any);

    id
      ? editCleanOutDetail(formData, id).then(() => navigate("/geochemistry-clean"))
      : addCleanOutDetail(formData).then(() => navigate("/geochemistry-clean"));
  };

  const { handleSubmit, handleChange, values, errors, setFieldValue } = useFormik({
    onSubmit,
    initialValues,
    validationSchema,
    enableReinitialize: true,
  });

  const selectFiles = async (event: ChangeEvent<HTMLInputElement>, identifier: string) => {
    const file = event.target.files?.item(0);
    if (file) {
      const base64 = await convertBase64(file);
      setFieldValue(identifier, file);
      setFieldValue(`${identifier.replace("Url", "")}Base64`, base64);
    }
  };

  useEffect(() => {
    orcGridStore.getOrcOptions().then((res) => setOrcOptions(res));

    if (!id) return;
    getCleanOutDetail(id);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid container>
      <Grid item xs={12}>
        <RkdCard title="Clean Out History" subtitle={id ? "Edit" : "Add"}>
          {loadingDetail || orcGridStore.orcGetOptionsLoading ? (
            <Box sx={{ textAlign: "center" }}>
              <CircularProgress />
            </Box>
          ) : (
            <>
              <Grid container spacing="6px">
                <Grid item xs={2}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      label="Cleaning Date"
                      value={values.cleaningDate}
                      disabled={biweeklyStore.loading}
                      inputFormat="dd MMM yyyy"
                      onChange={(e: any) => setFieldValue("cleaningDate", e)}
                      renderInput={(props) => <TextField required fullWidth name="cleaningDate" {...props} />}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={2}>
                  <TextField select label="ORC" value={values.orcId} onChange={handleChange} fullWidth name="orcId">
                    {orcOptions.map((item, idx) => (
                      <MenuItem key={idx} value={item.value}>
                        {item.text}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={8}>
                  <TextField
                    label="Cleaning Mechanism"
                    name="mechanism"
                    minRows={3}
                    fullWidth
                    type="text"
                    variant="outlined"
                    onChange={handleChange}
                    value={values.mechanism}
                  />
                </Grid>
                <Grid item xs={12} sx={{ mt: "24px" }}>
                  <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "12px" }}>
                    <span style={{ fontWeight: "bold" }}>Before: </span>
                    {!!errors.imageUrlBefore && <FormHelperText sx={{ color: "red" }}>Required</FormHelperText>}
                    <Button component="label" variant="outlined" startIcon={<UploadOutlined />}>
                      Upload File
                      <input
                        type="file"
                        accept=".jpg, .png, .jpeg"
                        hidden
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => selectFiles(e, "imageUrlBefore")}
                        name="postScreenshotFile"
                      />
                    </Button>
                  </Box>
                  <Box sx={{ textAlign: "center", display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <img src={values.imageBeforeBase64} alt="before" width={200} height={200} style={{ objectFit: "contain" }} />
                    {!!values.imageUrlBefore ? values.imageUrlBefore.name : ""}
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "12px" }}>
                    <span style={{ fontWeight: "bold" }}>After: </span>
                    <Button component="label" variant="outlined" startIcon={<UploadOutlined />}>
                      Upload File
                      <input
                        type="file"
                        accept=".jpg, .png, .jpeg"
                        hidden
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => selectFiles(e, "imageUrlAfter")}
                        name="postScreenshotFile"
                      />
                    </Button>
                  </Box>
                  <Box sx={{ textAlign: "center", display: "flex", flexDirection: "column", alignItems: "center" }}>
                    {values.imageAfterBase64 && (
                      <img src={values.imageAfterBase64} alt="after" width={200} height={200} style={{ objectFit: "contain" }} />
                    )}
                    {!!values.imageUrlAfter ? values.imageUrlAfter.name : ""}
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "12px" }}>
                    <span style={{ fontWeight: "bold" }}>Sample: </span>
                    <Button component="label" variant="outlined" startIcon={<UploadOutlined />}>
                      Upload File
                      <input
                        type="file"
                        accept=".jpg, .png, .jpeg"
                        hidden
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => selectFiles(e, "imageUrlSample")}
                        name="postScreenshotFile"
                      />
                    </Button>
                  </Box>
                  <Box sx={{ textAlign: "center", display: "flex", flexDirection: "column", alignItems: "center" }}>
                    {values.imageSampleBase64 && (
                      <img src={values.imageSampleBase64} alt="after" width={200} height={200} style={{ objectFit: "contain" }} />
                    )}
                    {!!values.imageUrlSample ? values.imageUrlSample.name : ""}
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "12px" }}>
                    <span style={{ fontWeight: "bold" }}>XRD: </span>
                    <Button component="label" variant="outlined" startIcon={<UploadOutlined />}>
                      Upload File
                      <input
                        type="file"
                        accept=".jpg, .png, .jpeg"
                        hidden
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => selectFiles(e, "imageUrlXrd")}
                        name="postScreenshotFile"
                      />
                    </Button>
                  </Box>
                  <Box sx={{ textAlign: "center", display: "flex", flexDirection: "column", alignItems: "center" }}>
                    {values.imageXrdBase64 && (
                      <img src={values.imageXrdBase64} alt="after" width={200} height={200} style={{ objectFit: "contain" }} />
                    )}
                    {!!values.imageUrlXrd ? values.imageUrlXrd.name : ""}
                  </Box>
                </Grid>
              </Grid>
              <Box>
                <Box sx={{ mt: "24px", display: "flex", gap: "12px", justifyContent: "flex-end" }}>
                  <Box>
                    <Button disabled={biweeklyStore.loadingAdd} variant="contained" color="error" onClick={() => navigate("/geochemistry-clean")}>
                      Cancel
                    </Button>
                  </Box>
                  <Box>
                    <LoadingButton
                      loading={biweeklyStore.loadingAdd}
                      onClick={() => handleSubmit()}
                      type="submit"
                      variant="contained"
                      color="primary"
                    >
                      Submit
                    </LoadingButton>
                  </Box>
                </Box>
              </Box>
            </>
          )}
        </RkdCard>
      </Grid>
    </Grid>
  );
}

export default observer(CleanOutHistoryPageCreate);
