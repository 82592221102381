import { observer } from "mobx-react-lite";
import { FptConnectedWellDetail, FptTestWellDetail } from "../../app/models/fpt";
import { DataGridPremium, GridColDef } from "@mui/x-data-grid-premium";
import { Button, TextField } from "@mui/material";
import { Box } from "@mui/system";
import { useCallback, useEffect, useState } from "react";
import { useStore } from "../../app/stores/store";
import { GridActionsCellItem } from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";

interface IProps {
    fptConnectedWellDetail: FptConnectedWellDetail;
}

function FPTConnectedWellGrid(props: IProps){
    const { ftpStore } = useStore();
    const [ connectedWellTable, setConnectedWellTable] = useState(props.fptConnectedWellDetail.table);
    const [enthalphy, setEnthalphy] = useState(0);
    const [prodPercentage, setProdPercentage] = useState(0);
    const [dWsWhp, setDWsWhp] = useState(0);

    // const fetchChart = React.useCallback((fpt : FptDetail) => {
    //     ftpStore.fptChart = [];
    //     let arrayChartSeries : any = [];

    //     let arrayChart : Array<IFPTChartDataResponse> = []
    //     fpt.vessel.table.forEach(data => {
    //         let initialDataFPTChartDataResponse : IFPTChartDataResponse = {
    //             x : data.psep,
    //             y : data.wkph
    //         }
    //         arrayChart.push(initialDataFPTChartDataResponse);
    //     });
    //     let initialDataFPTChartSeriesResponse : IFPTChartSeriesResponse = {
    //         name : "Vessel",
    //         data : arrayChart
    //     }
    //     arrayChartSeries.push(initialDataFPTChartSeriesResponse);

    //     arrayChart = [];
    //     fpt.testResults.forEach(data => {
    //         let initialDataFPTChartDataResponse : IFPTChartDataResponse = {
    //             x : data.psep,
    //             y : data.whp
    //         }
    //         arrayChart.push(initialDataFPTChartDataResponse);
    //     });
    //     initialDataFPTChartSeriesResponse = {
    //         name : "Test Well",
    //         data : arrayChart
    //     }
    //     arrayChartSeries.push(initialDataFPTChartSeriesResponse);

    //     arrayChart = [];
    //     fpt.connectedWells.forEach(data => {
    //         data.table.forEach(data1 => {
    //             let initialDataFPTChartDataResponse : IFPTChartDataResponse = {
    //                 x : data1.whp,
    //                 y : data1.fcv
    //             }
    //             arrayChart.push(initialDataFPTChartDataResponse);
    //         })
    //     });
    //     initialDataFPTChartSeriesResponse = {
    //         name : "Other Well(s)",
    //         data : arrayChart
    //     }
    //     arrayChartSeries.push(initialDataFPTChartSeriesResponse);
    //     ftpStore.fptChart.push(arrayChartSeries);
    // }, [ftpStore]);

    useEffect(() => {
        setConnectedWellTable(props.fptConnectedWellDetail.table);
        if(props.fptConnectedWellDetail.enthalphy != null){
            setEnthalphy(props.fptConnectedWellDetail.enthalphy)
        }
        else{
            setEnthalphy(0)
        }
        if(props.fptConnectedWellDetail.prodPercentage != null){
            setProdPercentage(props.fptConnectedWellDetail.prodPercentage)
        }
        else{
            setProdPercentage(0)
        }
        if(props.fptConnectedWellDetail.dWsWhp != null){
            setDWsWhp(props.fptConnectedWellDetail.dWsWhp)
        }
        else{
            setDWsWhp(0)
        }
    }, [props.fptConnectedWellDetail.dWsWhp, props.fptConnectedWellDetail.enthalphy, props.fptConnectedWellDetail.prodPercentage, props.fptConnectedWellDetail.table]);

    const gridColumns: GridColDef[] = [
        { field: 'fcv', headerName: 'FCV (%)', width: 90, type: "number", editable: true },
        { field: 'whp', headerName: 'WHP (psg)', width: 90, type: "number", editable: true },
        {
            field: "actions",
            headerName: "Action",
            type: "actions",
            width: 70,
            getActions: (params) => [
              <GridActionsCellItem icon={<DeleteIcon />} color="error" label="Delete" onClick={() => handleDeleteRow(params.row.sequence)} />,
            ],
          },
    ]

    const handleDeleteRow = (sequence: number) => {
        setConnectedWellTable(connectedWellTable.filter((row) => row.sequence !== sequence));
        ftpStore.setConnectedWell(
            connectedWellTable.filter((row) => row.sequence !== sequence),
            props.fptConnectedWellDetail.wellId
        );
      };
      
    const handleAddRow = () => {
        let detail = new FptTestWellDetail();
        detail.sequence = connectedWellTable.length > 0 ? connectedWellTable[connectedWellTable.length - 1].sequence + 1 : 1;
        detail.fcv = 0;
        detail.whp = 0;
        ftpStore.addConnectedWell(detail, props.fptConnectedWellDetail.wellId);
        setConnectedWellTable((nextTestWell:any) => [...nextTestWell, detail]);
        // fetchChart(ftpStore.fpt);
    }

    const processRowUpdate = useCallback(
        async(newRow:any) => {
            const updatedRow = { ...newRow, isNew: false };
            setConnectedWellTable(connectedWellTable.map((row) => (row.sequence === newRow.sequence ? updatedRow : row)));
            ftpStore.setConnectedWell(connectedWellTable.map((row) => (row.sequence === newRow.sequence ? updatedRow : row)), props.fptConnectedWellDetail.wellId);
            // fetchChart(ftpStore.fpt);
            return updatedRow;
        }, [connectedWellTable, ftpStore, props.fptConnectedWellDetail.wellId]
    );

    function handleChangeEnthalphy(event:any) {
        setEnthalphy(event.target.value);
        ftpStore.setConnectedWellText(props.fptConnectedWellDetail.wellId, event.target.value, prodPercentage, dWsWhp);
    }

    function handleChangeProdPercentage(event:any) {
        setProdPercentage(event.target.value);
        ftpStore.setConnectedWellText(props.fptConnectedWellDetail.wellId, enthalphy, event.target.value, dWsWhp);
    }

    function handleChangeDWsWhp(event:any) {
        setDWsWhp(event.target.value);
        ftpStore.setConnectedWellText(props.fptConnectedWellDetail.wellId, enthalphy, prodPercentage, event.target.value);
    }

    return(
        <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'left', gap: 2 }}>
            <b>{props.fptConnectedWellDetail.wellName}</b>
            <Button variant='contained' color='primary' onClick={handleAddRow} sx={{ width: '10%' }}>Add</Button>
            <DataGridPremium
                columns={gridColumns} 
                rows={connectedWellTable} 
                getRowId={(row) => row.sequence} 
                autoHeight 
                getRowHeight={() => 100}
                onProcessRowUpdateError={(e) => console.error(e)} 
                processRowUpdate={processRowUpdate}
                // initialState={{
                // sorting: {
                //     sortModel: [{ field: 'sequence', sort: 'asc' }],
                // },
                // }}
            />
            <TextField name="enthalphy" type="text" label="Enthalpy" value={enthalphy} onChange={handleChangeEnthalphy} />
            <TextField name="prod" type="text" label="% prod" value={prodPercentage} onChange={handleChangeProdPercentage} />
            <TextField name="dws" type="text" label="dWs/dWHP" value={dWsWhp} onChange={handleChangeDWsWhp} />
        </Box>
        
    );
}

export default observer(FPTConnectedWellGrid);