import { observer } from "mobx-react-lite";
import React, { useCallback, useEffect, useState } from "react";
import RkdCard from "../../app/component/card/RkdCard";
import { Box } from "@mui/system";
import { Autocomplete, Button, FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { IObjectOptions } from "../../app/models/object";
import { useStore } from "../../app/stores/store";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { format } from "date-fns";
import { LoadingButton } from "@mui/lab";
import { FptDetail, FptTestWell } from "../../app/models/fpt";
import FPTGenerateForm from "./FPTGenerateForm";
import { TSelectedData } from "../pressureandtemp/decimation/PTDecimationForm";

function FPTForm() {
  const { objectStore, ftpStore } = useStore();
  const [selectedWell, setSelectedWell] = useState<TSelectedData | undefined>(undefined);
  const [selected, setSelected] = useState("");
  const [selectedText, setSelectedText] = useState(ftpStore.fpt.wellId);
  const [dateSurvey, setDateSurvey] = useState(new Date());
  const { wellId } = useParams<{ wellId: string }>();
  const { surveyDate } = useParams<{ surveyDate: string }>();
  const [generateForm, setGenerateForm] = useState<Array<any>>([]);
  const [pAtm, setpAtm] = useState(0);
  const [remarks, setRemarks] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const [editable, setEditable] = useState(true);
  const [readOnly, setReadOnly] = useState(false);
  const { pathname, search, hash } = useLocation();

  const fetchTable = useCallback(
    (selectedWell: any, selectedWellText: any, selectedDate: Date) => {
      try {
        ftpStore.getFptChart(selectedWell, format(selectedDate, "yyyy-MM-dd")).then((chart) => {
          ftpStore.setFPTChart(chart.charts);
        });
        ftpStore.getFpt(selectedWell, format(selectedDate, "yyyy-MM-dd")).then((fpt) => {
          ftpStore.setFPT(fpt);
          let generateForm: Array<any> = [];
          setGenerateForm([]);
          let pAtmInput = 0;
          if (ftpStore.fpt.pAtm === null) {
            pAtmInput = 0;
          } else {
            pAtmInput = ftpStore.fpt.pAtm;
          }
          let remarksInput = "";
          if (ftpStore.fpt.remarks === null) {
            remarksInput = "";
          } else {
            remarksInput = ftpStore.fpt.remarks;
          }
          setpAtm(pAtmInput);
          setRemarks(remarksInput);
          let wellDetail = ftpStore.fpt.testWells.find((x) => x.wellId === ftpStore.fpt.wellId);
          if (wellDetail === undefined) {
            let listTestWell: any = [];
            wellDetail = new FptTestWell();
            wellDetail.wellId = selectedWell;
            wellDetail.wellName = selectedWellText;
            wellDetail.enthalphy = 0;
            wellDetail.table = [];
            listTestWell.push(wellDetail);
            ftpStore.fpt.testWells = listTestWell;
          }
          generateForm.push(
            <FPTGenerateForm
              key={0}
              nameWell={selectedWellText}
              testWells={ftpStore.fpt.testWells}
              wells={ftpStore.fpt.connectedWells}
              vessel={ftpStore.fpt.vessel}
              testWellsResult={ftpStore.fpt.testResults}
            />
          );
          setGenerateForm(generateForm);
        });
      } catch (e) {
        console.error(e);
      }
    },
    [ftpStore]
  );

  useEffect(() => {
    let route = location.pathname.split("/")[2];
    if (route === "report") {
      setReadOnly(true);
      setEditable(false);
    }

    if (wellId && surveyDate) {
      let tempWell = ftpStore.objectFptObtions.find((item) => item.value === wellId);
      setSelectedWell({ label: tempWell?.text ?? "", value: tempWell?.value ?? "" });
      setDateSurvey(new Date(surveyDate + "Z"));
      ftpStore.getFptChart(wellId, format(new Date(surveyDate + "Z"), "yyyy-MM-dd")).then((chart) => {
        ftpStore.setFPTChart(chart.charts);
      });
      objectStore.getObjectDetail(wellId).then((object) => {
        setSelectedText(object.objectName);
        ftpStore.getFpt(wellId, format(new Date(surveyDate + "Z"), "yyyy-MM-dd")).then((fpt) => {
          ftpStore.setFPT(fpt);
          let generateForm: Array<any> = [];
          setGenerateForm([]);
          let pAtmInput = 0;
          if (ftpStore.fpt.pAtm === null) {
            pAtmInput = 0;
          } else {
            pAtmInput = ftpStore.fpt.pAtm;
          }
          let remarksInput = "";
          if (ftpStore.fpt.remarks === null) {
            remarksInput = "";
          } else {
            remarksInput = ftpStore.fpt.remarks;
          }
          setpAtm(pAtmInput);
          setRemarks(remarksInput);
          let wellDetail = ftpStore.fpt.testWells.find((x) => x.wellId === ftpStore.fpt.wellId);
          if (wellDetail === undefined) {
            let listTestWell: any = [];
            wellDetail = new FptTestWell();
            wellDetail.wellId = wellId;
            wellDetail.wellName = object.objectName;
            wellDetail.enthalphy = 0;
            wellDetail.table = [];
            listTestWell.push(wellDetail);
            ftpStore.fpt.testWells = listTestWell;
          }
          generateForm.push(
            <FPTGenerateForm
              key={0}
              nameWell={object.objectName}
              testWells={ftpStore.fpt.testWells}
              wells={ftpStore.fpt.connectedWells}
              vessel={ftpStore.fpt.vessel}
              testWellsResult={ftpStore.fpt.testResults}
            />
          );
          setGenerateForm(generateForm);
        });
      });
    }
  }, [ftpStore, location.pathname, objectStore, surveyDate, wellId]);

  function handleChange(val: TSelectedData) {
    setSelectedWell(val);
    objectStore.getObjectDetail(val.value).then((object) => {
      setSelectedText(object.objectName);
      fetchTable(val.value, object.objectName, dateSurvey);
      ftpStore.fpt.wellId = val.value;
    });
  }

  function handleChangeSurveyDate(event: any) {
    setDateSurvey(event);
    fetchTable(selectedWell?.value, selectedText, event);
    ftpStore.fpt.surveyDate = event;
  }

  useEffect(() => {
    ftpStore.getObjectOptions();
  }, [ftpStore]);

  function handleChangePAtm(event: any) {
    setpAtm(event.target.value);
    ftpStore.fpt.pAtm = event.target.value;
  }

  function handleChangeRemarks(event: any) {
    setRemarks(event.target.value);
    ftpStore.fpt.remarks = event.target.value;
  }

  const onCancel = async () => {
    ftpStore.fpt = new FptDetail();
    navigate("/fpt");
  };

  const onSubmit = async () => {
    ftpStore.fpt.wellId = selectedWell?.value ?? "";
    ftpStore.fpt.surveyDate = dateSurvey;
    ftpStore.fpt.pAtm = pAtm;
    ftpStore.fpt.remarks = remarks;
    ftpStore.setFPTForm(ftpStore.fpt);
    await ftpStore.createFPT(ftpStore.fptForm);
    navigate("/fpt");
  };

  return (
    <RkdCard title="Flow Performance Test" subtitle="Form">
      <Box key={1} sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
        <Box sx={{ display: "flex", flexDirection: "column", width: "100%", gap: 2 }}>
          <Autocomplete
            disablePortal
            fullWidth
            sx={{ marginBotom: 0 }}
            onChange={(e: any, val: any) => handleChange(val)}
            disableClearable
            value={selectedWell}
            getOptionLabel={(option) => option.label}
            options={ftpStore.objectFptObtions.map((item) => ({ label: item.text, value: item.value }))}
            renderInput={(params) => <TextField {...params} label="Well Name" />}
          />
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="Date"
              disabled={!selectedWell}
              readOnly={readOnly}
              onChange={handleChangeSurveyDate}
              value={dateSurvey}
              renderInput={(props) => <TextField label="Survey Date" {...props} />}
            />
          </LocalizationProvider>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column", width: "100%", gap: 2 }}>
          <TextField name="patm" type="text" label="P atm" value={pAtm} onChange={handleChangePAtm} InputProps={{ readOnly: readOnly }} />
          <TextField
            name="remarks"
            type="text"
            label="Remarks"
            rows={10}
            value={remarks}
            onChange={handleChangeRemarks}
            InputProps={{ readOnly: readOnly }}
          />
        </Box>
      </Box>
      <Box key={2}>{generateForm}</Box>
      <Box sx={{ display: "flex", flexDirection: "row", gap: 1, width: "100%", alignItems: "center", justifyContent: "flex-end" }}>
        <Button variant="contained" color="error" onClick={onCancel}>
          Cancel
        </Button>
        {pathname.includes("report") ? (
          ""
        ) : (
          <LoadingButton type="button" variant="contained" color="primary" onClick={onSubmit}>
            Submit
          </LoadingButton>
        )}
      </Box>
    </RkdCard>
  );
}

export default observer(FPTForm);
