import { TextField, Button } from "@mui/material";
import { Box } from "@mui/system";
import { DataGridPremium, gridClasses, GridColDef } from "@mui/x-data-grid-premium";
import { observer } from "mobx-react-lite";
import React, { useCallback, useEffect, useState } from "react";
import { IBrineSampleAnalysisTables } from "../../app/models/brine";
import { useStore } from "../../app/stores/store";
import {
  averageConcentrationFormula,
  dilutionFactorFormula,
  finalSampConcentrateFormula,
  rawSampConcentrateFormula,
  totalDilutionFactorFormula,
} from "./helper/brine/formula";
import Checkbox from "@mui/material/Checkbox";
import DeleteIcon from "@mui/icons-material/Delete";
import { GridActionsCellItem } from "@mui/x-data-grid";

function TFTProcessBrineStep2() {
  const { tftSteamStore } = useStore();

  const [sampleAnalysis, setSampleAnalysis] = useState(tftSteamStore.tft.brine.sampleAnalysis.table);

  const handleAddRow = () => {
    setSampleAnalysis([
      ...sampleAnalysis,
      {
        sequence: sampleAnalysis.length + 1,
        seriesNumber: "",
        labNumber: sampleAnalysis.length + 1,
        readingAbsorbanceUnit: 0,
        dilutionInit1: 0,
        dilutionFinal1: 0,
        dilutionFactor1: 0,
        dilutionInit2: 0,
        dilutionFinal2: 0,
        dilutionFactor2: 0,
        dilutionInit3: 0,
        dilutionFinal3: 0,
        dilutionFactor3: 0,
        dilutionInit4: 0,
        dilutionFinal4: 0,
        dilutionFactor4: 0,
        totalDilutionFactor: 0,
        sampleInSpike: 0,
        stdInSpike: 0,
        rawSampleConcentrate: 0,
        finalSampleConcentrate: 0,
        continue: false,
        averageConcentrate: 0,
        rsd: 0,
        recovery: 0,
        result: 0,
      },
    ]);

    tftSteamStore.addBrineResultTable();
  };

  const deleteRow = (sequence: number) => {
    let data = sampleAnalysis.filter((x) => x.sequence !== sequence);
    tftSteamStore.clearBrineResultTable();
    data.forEach((x, index) => {
      x.sequence = index + 1;
      x.labNumber = index + 1;
      tftSteamStore.addBrineResultTable();
    });
    setSampleAnalysis(data);
  };

  const handleRowEditSampleAnalysis = useCallback(
    (newValue: IBrineSampleAnalysisTables) => {
      const data: IBrineSampleAnalysisTables[] = tftSteamStore.tft.brine.sampleAnalysis.table.map((current) => {
        if (current.sequence !== newValue.sequence) return current;

        const newDilutionFactor1 = dilutionFactorFormula(newValue.dilutionInit1, newValue.dilutionFinal1);
        const newDilutionFactor2 = dilutionFactorFormula(newValue.dilutionInit2, newValue.dilutionFinal2);
        const newDilutionFactor3 = dilutionFactorFormula(newValue.dilutionInit3, newValue.dilutionFinal3);
        const newDilutionFactor4 = dilutionFactorFormula(newValue.dilutionInit4, newValue.dilutionFinal4);
        const newTotDilutionFactor = totalDilutionFactorFormula(newDilutionFactor1, newDilutionFactor2, newDilutionFactor3, newDilutionFactor4);
        const newRawSampleCon = rawSampConcentrateFormula(
          newValue.readingAbsorbanceUnit,
          tftSteamStore.tft.brine.ltAnalyses,
          tftSteamStore.tft.brine.regressions
        );
        const newFinalSampleCon = finalSampConcentrateFormula(newValue.readingAbsorbanceUnit, newTotDilutionFactor, newRawSampleCon);
        const newResult = newFinalSampleCon / 1000;
        let newAverageConcentrate = 0;

        if (newValue.sequence === 0) {
          newAverageConcentrate = averageConcentrationFormula(0, newFinalSampleCon, true);
        } else {
          newAverageConcentrate = averageConcentrationFormula(
            tftSteamStore.tft.brine.sampleAnalysis.table[newValue.sequence - 1].finalSampleConcentrate,
            newFinalSampleCon,
            false
          );
        }

        return {
          sequence: newValue.sequence,
          seriesNumber: newValue.seriesNumber,
          labNumber: newValue.labNumber,
          readingAbsorbanceUnit: newValue.readingAbsorbanceUnit,
          dilutionInit1: newValue.dilutionInit1,
          dilutionFinal1: newValue.dilutionFinal1,
          dilutionFactor1: newDilutionFactor1,
          dilutionInit2: newValue.dilutionInit2,
          dilutionFinal2: newValue.dilutionFinal2,
          dilutionFactor2: newDilutionFactor2,
          dilutionInit3: newValue.dilutionInit3,
          dilutionFinal3: newValue.dilutionFinal3,
          dilutionFactor3: newDilutionFactor3,
          dilutionInit4: newValue.dilutionInit4,
          dilutionFinal4: newValue.dilutionFinal4,
          dilutionFactor4: newDilutionFactor4,
          totalDilutionFactor: newTotDilutionFactor,
          sampleInSpike: newValue.sampleInSpike,
          stdInSpike: newValue.stdInSpike,
          rawSampleConcentrate: newRawSampleCon,
          finalSampleConcentrate: newFinalSampleCon,
          continue: newValue.continue,
          averageConcentrate: newAverageConcentrate,
          rsd: 0,
          recovery: 0,
          result: newResult,
        };
      });
      setSampleAnalysis(data);

      return newValue;
    },
    [tftSteamStore]
  );

  const handleContinue = (event: React.ChangeEvent<HTMLInputElement>, sequence: number) => {
    const data: IBrineSampleAnalysisTables[] = tftSteamStore.tft.brine.sampleAnalysis.table.map((newValue) => {
      if (newValue.sequence !== sequence) return newValue;

      let newAverageConcentrate = 0;
      if (event.target.checked === true) {
        if (newValue.sequence === 0) {
          newAverageConcentrate = averageConcentrationFormula(0, newValue.finalSampleConcentrate, true);
        } else {
          newAverageConcentrate = averageConcentrationFormula(
            tftSteamStore.tft.brine.sampleAnalysis.table[newValue.sequence - 1].finalSampleConcentrate,
            newValue.finalSampleConcentrate,
            false
          );
        }
      } else {
        newAverageConcentrate = 0;
      }

      return {
        sequence: newValue.sequence,
        seriesNumber: newValue.seriesNumber,
        labNumber: newValue.labNumber,
        readingAbsorbanceUnit: newValue.readingAbsorbanceUnit,
        dilutionInit1: newValue.dilutionInit1,
        dilutionFinal1: newValue.dilutionFinal1,
        dilutionFactor1: newValue.dilutionFactor1,
        dilutionInit2: newValue.dilutionInit2,
        dilutionFinal2: newValue.dilutionFinal2,
        dilutionFactor2: newValue.dilutionFactor2,
        dilutionInit3: newValue.dilutionInit3,
        dilutionFinal3: newValue.dilutionFinal3,
        dilutionFactor3: newValue.dilutionFactor3,
        dilutionInit4: newValue.dilutionInit4,
        dilutionFinal4: newValue.dilutionFinal4,
        dilutionFactor4: newValue.dilutionFactor4,
        totalDilutionFactor: newValue.totalDilutionFactor,
        sampleInSpike: newValue.sampleInSpike,
        stdInSpike: newValue.stdInSpike,
        rawSampleConcentrate: newValue.rawSampleConcentrate,
        finalSampleConcentrate: newValue.finalSampleConcentrate,
        continue: event.target.checked,
        averageConcentrate: newAverageConcentrate,
        rsd: newValue.rsd,
        recovery: newValue.recovery,
        result: newValue.result,
      };
    });
    setSampleAnalysis(data);
    return event;
  };

  const columnSampleAnalysis: GridColDef[] = [
    { field: "sequence", headerName: "Run #", width: 120, type: "number", align: "center", headerAlign: "center", headerClassName: "tab-header" },
    {
      field: "seriesNumber",
      headerName: "Series Number",
      width: 120,
      type: "string",
      editable: true,
      align: "center",
      headerAlign: "center",
      headerClassName: "tab-header",
    },
    {
      field: "labNumber",
      headerName: "Lab Number",
      width: 120,
      type: "number",
      editable: true,
      align: "center",
      headerAlign: "center",
      headerClassName: "tab-header",
    },
    {
      field: "readingAbsorbanceUnit",
      headerName: "Reading Absorb",
      width: 120,
      type: "number",
      editable: true,
      align: "center",
      headerAlign: "center",
      headerClassName: "tab-header",
    },
    {
      field: "dilutionInit1",
      headerName: "Dilution #1 Initial Vol (mL)",
      width: 120,
      type: "number",
      editable: true,
      align: "center",
      headerAlign: "center",
      headerClassName: "tab-header",
    },
    {
      field: "dilutionFinal1",
      headerName: "Dilution #1 Final Volume (mL)",
      width: 120,
      type: "number",
      editable: true,
      align: "center",
      headerAlign: "center",
      headerClassName: "tab-header",
    },
    {
      field: "dilutionFactor1",
      headerName: "Dilution #1 Factor",
      width: 120,
      type: "number",
      cellClassName: "disabled-cell",
      align: "center",
      headerAlign: "center",
      headerClassName: "tab-header",
    },
    {
      field: "dilutionInit2",
      headerName: "Dilution #2 Initial Vol (mL)",
      width: 120,
      type: "number",
      editable: true,
      align: "center",
      headerAlign: "center",
      headerClassName: "tab-header",
    },
    {
      field: "dilutionFinal2",
      headerName: "Dilution #2 Final Volume (mL)",
      width: 120,
      type: "number",
      editable: true,
      align: "center",
      headerAlign: "center",
      headerClassName: "tab-header",
    },
    {
      field: "dilutionFactor2",
      headerName: "Dilution #2 Factor",
      width: 120,
      type: "number",
      cellClassName: "disabled-cell",
      align: "center",
      headerAlign: "center",
      headerClassName: "tab-header",
    },
    {
      field: "totalDilutionFactor",
      headerName: "Total Dilution Factor",
      width: 120,
      type: "number",
      cellClassName: "disabled-cell",
      align: "center",
      headerAlign: "center",
      headerClassName: "tab-header",
    },
    {
      field: "sampleInSpike",
      headerName: "mL Sample in Spike",
      width: 120,
      type: "number",
      editable: true,
      align: "center",
      headerAlign: "center",
      headerClassName: "tab-header",
    },
    {
      field: "stdInSpike",
      headerName: "mL STD in Spike",
      width: 120,
      type: "number",
      editable: true,
      align: "center",
      headerAlign: "center",
      headerClassName: "tab-header",
    },
    {
      field: "rawSampleConcentrate",
      headerName: "Raw Sample Concentrate",
      width: 120,
      type: "number",
      cellClassName: "disabled-cell",
      align: "center",
      headerAlign: "center",
      headerClassName: "tab-header",
    },
    {
      field: "finalSampleConcentrate",
      headerName: "Final Sample Concentration (ppb)",
      width: 120,
      type: "number",
      cellClassName: "disabled-cell",
      align: "center",
      headerAlign: "center",
      headerClassName: "tab-header",
    },
    {
      field: "continue",
      headerName: "",
      width: 70,
      type: "number",
      align: "center",
      headerAlign: "center",
      headerClassName: "tab-header",
      renderCell: (params) => {
        return (
          <Checkbox
            checked={params.row.continue}
            onChange={(e) => handleContinue(e, params.row.sequence)}
            inputProps={{ "aria-label": "controlled" }}
          />
        );
      },
    },
    {
      field: "averageConcentrate",
      headerName: "Average Concentrate on (ppb)",
      width: 120,
      type: "number",
      cellClassName: "disabled-cell",
      align: "center",
      headerAlign: "center",
      headerClassName: "tab-header",
    },
    {
      field: "rsd",
      headerName: "% RSD",
      width: 120,
      type: "number",
      cellClassName: "disabled-cell",
      align: "center",
      headerAlign: "center",
      headerClassName: "tab-header",
    },
    {
      field: "recovery",
      headerName: "% Recovery",
      width: 120,
      type: "number",
      cellClassName: "disabled-cell",
      align: "center",
      headerAlign: "center",
      headerClassName: "tab-header",
    },
    {
      field: "result",
      headerName: "Result",
      width: 120,
      type: "number",
      cellClassName: "disabled-cell",
      align: "center",
      headerAlign: "center",
      headerClassName: "tab-header",
    },
    {
      field: "actions",
      headerName: "Action",
      type: "actions",
      getActions: (params) => {
        return [<GridActionsCellItem icon={<DeleteIcon />} color="error" label="Delete" onClick={() => deleteRow(params.row.sequence)} />];
      },
    },
  ];

  useEffect(() => {
    tftSteamStore.setSampleAnalysisTableBrine(sampleAnalysis);
  }, [sampleAnalysis, tftSteamStore]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
        "& .disabled-cell": {
          backgroundColor: "#e2e2e2",
        },
        "& .tab-header": {
          backgroundColor: "#D4E7C5",
          fontWeight: "bold",
        },
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "column", gap: 2, width: "35%" }}>
        <TextField
          name="analysisNumber"
          label="Analysis Number"
          variant="outlined"
          fullWidth
          value={tftSteamStore.tft.brine.sampleAnalysis.analysisNumber}
          onChange={(e) => tftSteamStore.setSampleAnalysisNumber(e.target.value)}
        />
        <TextField
          name="location"
          label="Lab/Location"
          variant="outlined"
          fullWidth
          value={tftSteamStore.tft.brine.sampleAnalysis.location}
          onChange={(e) => tftSteamStore.setSampleAnalysisLocation(e.target.value)}
        />
        <TextField
          name="analyte"
          label="Analyte"
          variant="outlined"
          fullWidth
          value={tftSteamStore.tft.brine.sampleAnalysis.analyte}
          onChange={(e) => tftSteamStore.setSampleAnalysisAnalyte(e.target.value)}
        />
        <TextField
          name="analyst"
          label="Analyst"
          variant="outlined"
          fullWidth
          value={tftSteamStore.tft.brine.sampleAnalysis.analyst}
          onChange={(e) => tftSteamStore.setSampleAnalysisAnalyst(e.target.value)}
        />
        <TextField
          name="remarks"
          label="Remarks"
          multiline
          rows={4}
          variant="outlined"
          value={tftSteamStore.tft.brine.sampleAnalysis.remarks}
          onChange={(e) => tftSteamStore.setSampleAnalysisRemarks(e.target.value)}
        />
      </Box>
      <Button variant="contained" color="primary" onClick={handleAddRow} sx={{ width: "10%" }}>
        Add
      </Button>
      <DataGridPremium
        columns={columnSampleAnalysis}
        rows={sampleAnalysis}
        getRowId={(row) => row.sequence}
        autoHeight
        getRowHeight={() => "auto"}
        onProcessRowUpdateError={(e) => console.error(e)}
        processRowUpdate={handleRowEditSampleAnalysis}
        showCellVerticalBorder
        showColumnVerticalBorder
        initialState={{
          sorting: {
            sortModel: [{ field: "sequence", sort: "asc" }],
          },
        }}
        sx={{
          [`& .${gridClasses.cell}`]: {
            py: 2,
            borderColor: "#000000",
          },
          borderColor: "#000000",
          "& .MuiDataGrid-columnHeader": {
            backgroundColor: "#D4E7C5",
            fontWeight: "bold",
            border: 1,
            borderRight: 0,
            borderTop: 1,
            borderBottom: 0,
          },
          "& .MuiDataGrid-cell": {
            border: 1,
            borderRight: 0,
            borderTop: 1,
            borderBottom: 0,
            // add more css for customization
          },
        }}
      />
    </Box>
  );
}

export default observer(TFTProcessBrineStep2);
