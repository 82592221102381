export const defaultDecimatedData = {
  depth: 0,
  depthDecimated: 0,
  pressure: 0,
  pressureDecimated: 0,
  segment: 0,
  segmentStatus: "",
  sequence: 0,
  speed: 0,
  temperature: 0,
  temperatureDecimated: 0,
  time: new Date(),
};

export interface IDecimationGrid {
  id: string;
  wellId: string;
  wellName: string;
  surveyDate: Date;
  surveyNo: string;
  status: string;
}

export interface IDecimationSelect {
  index: number;
  value: string;
  text: string;
}

export interface IDecimationSegmentCount {
  value: number;
}

interface DecimatedUndecimated {
  depth: number;
  temperature: number;
  pressure: number;
}

export interface TChartStep3Payload {
  undecimateds: DecimatedUndecimated[];
  decimateds: DecimatedUndecimated[];
}

export interface IDecimation {
  wellId: string;
  surveyDate: Date;
  surveyNo: string;
  currentStep: string;
  table: Array<DecimationSegmentTable>;
  selectedSegments: Array<number>;
  decimationSegmentDetails: Array<DecimationSegmentDetailsTable>;
}

export interface IDecimationSegmentTable {
  sequence: number;
  segment: number | null;
  segmentStatus: string;
  time: Date;
  depth: number;
  speed: number;
  pressure: number;
  temperature: number;
}

export interface IDecimationSegmentSummaryTable {
  id: number;
  segment: number | null;
  begin: number;
  end: number;
  type: string;
}

export interface IDecimationSegmentDetailsTable {
  sequence: number;
  segment: number;
  depthDecimated: number;
  speedDecimated: number;
  pressureDecimated: number;
  temperatureDecimated: number;
  isEliminated: boolean;
}

export interface IDecimationChartSeriesResponse {
  name: string;
  data: Array<IDecimationChartDataResponse>;
}

export type TUploadTemplateDecimationDataResponse = {
  segment: number;
  segmentStatus: string;
  time: Date;
  depth: number;
  speed: number;
  pressure: number;
  temperature: number;
  sequence: number;
};

export type TUploadTemplateDecimationResponse = {
  success: boolean;
  data: DecimationSegmentTable[];
  messages: string;
};

export interface IDecimationChartDataResponse {
  x: number;
  y: number;
}

export interface IDecimationSelectedSegment {
  selected: boolean;
  segment: number;
}

export class DecimationGrid implements IDecimationGrid {
  id;
  wellId;
  wellName;
  surveyDate;
  surveyNo;
  status;

  constructor() {
    this.id = "";
    this.wellId = "";
    this.wellName = "";
    this.surveyDate = new Date();
    this.surveyNo = "";
    this.status = "";
  }

  clone(decimationGrid: DecimationGrid) {
    this.id = decimationGrid.id;
    this.wellId = decimationGrid.wellId;
    this.wellName = decimationGrid.wellName;
    this.surveyDate = decimationGrid.surveyDate;
    this.surveyNo = decimationGrid.surveyNo;
    this.status = decimationGrid.status;
  }
}

export class DecimationSegmentCount implements IDecimationSegmentCount {
  value;

  constructor() {
    this.value = 0;
  }

  clone(decimationSegmentCount: DecimationSegmentCount) {
    this.value = decimationSegmentCount.value;
  }
}

export class DecimationSelect implements IDecimationSelect {
  index;
  value;
  text;

  constructor() {
    this.index = 0;
    this.value = "";
    this.text = "";
  }

  clone(decimationSelectNumber: DecimationSelect) {
    this.index = decimationSelectNumber.index;
    this.value = decimationSelectNumber.value;
    this.text = decimationSelectNumber.text;
  }
}

export class Decimation implements IDecimation {
  wellId;
  surveyDate;
  surveyNo;
  currentStep;
  table: Array<DecimationSegmentTable>;
  selectedSegments: Array<number>;
  decimationSegmentDetails: Array<DecimationSegmentDetailsTable>;

  constructor() {
    this.wellId = "";
    this.surveyDate = new Date();
    this.surveyNo = "";
    this.currentStep = "";
    this.table = [];
    this.selectedSegments = [];
    this.decimationSegmentDetails = [];
  }

  clone(decimation: IDecimation) {
    this.wellId = decimation.wellId;
    this.surveyDate = decimation.surveyDate;
    this.surveyNo = decimation.surveyNo;
    this.currentStep = decimation.currentStep;
    this.table = decimation.table;
    this.selectedSegments = decimation.selectedSegments;
    this.decimationSegmentDetails = decimation.decimationSegmentDetails;
  }
}

export class DecimationSegmentTable implements IDecimationSegmentTable {
  sequence;
  segment = null;
  segmentStatus;
  time;
  depth;
  speed;
  pressure;
  temperature;

  constructor() {
    this.sequence = 0;
    this.segment = null;
    this.segmentStatus = "";
    this.time = new Date();
    this.depth = 0;
    this.speed = 0;
    this.pressure = 0;
    this.temperature = 0;
  }

  clone(decimationSegmentTable: DecimationSegmentTable) {
    this.sequence = decimationSegmentTable.sequence;
    this.segment = decimationSegmentTable.segment;
    this.segmentStatus = decimationSegmentTable.segmentStatus;
    this.time = decimationSegmentTable.time;
    this.depth = decimationSegmentTable.depth;
    this.speed = decimationSegmentTable.speed;
    this.pressure = decimationSegmentTable.pressure;
    this.temperature = decimationSegmentTable.temperature;
  }
}

export class DecimationSegmentSummaryTable implements IDecimationSegmentSummaryTable {
  id;
  segment = null;
  begin;
  end;
  type;

  constructor() {
    this.id = 0;
    this.segment = null;
    this.begin = 0;
    this.end = 0;
    this.type = "";
  }

  clone(decimationSegmentSummaryTable: DecimationSegmentSummaryTable) {
    this.id = decimationSegmentSummaryTable.id;
    this.segment = decimationSegmentSummaryTable.segment;
    this.begin = decimationSegmentSummaryTable.begin;
    this.end = decimationSegmentSummaryTable.end;
    this.type = decimationSegmentSummaryTable.type;
  }
}

export class DecimationSegmentDetailsTable implements IDecimationSegmentDetailsTable {
  sequence;
  segment;
  depthDecimated;
  speedDecimated;
  pressureDecimated;
  temperatureDecimated;
  isEliminated;

  constructor() {
    this.sequence = 0;
    this.segment = 0;
    this.depthDecimated = 0;
    this.speedDecimated = 0;
    this.pressureDecimated = 0;
    this.temperatureDecimated = 0;
    this.isEliminated = false;
  }

  clone(decimationSegmentDetailsTable: DecimationSegmentDetailsTable) {
    this.sequence = decimationSegmentDetailsTable.sequence;
    this.segment = decimationSegmentDetailsTable.segment;
    this.depthDecimated = decimationSegmentDetailsTable.depthDecimated;
    this.speedDecimated = decimationSegmentDetailsTable.speedDecimated;
    this.pressureDecimated = decimationSegmentDetailsTable.pressureDecimated;
    this.temperatureDecimated = decimationSegmentDetailsTable.temperatureDecimated;
    this.isEliminated = decimationSegmentDetailsTable.isEliminated;
  }
}

export class DecimationSelectedSegment implements IDecimationSelectedSegment {
  selected;
  segment;

  constructor() {
    this.selected = false;
    this.segment = 0;
  }

  clone(decimationSelectedSegment: DecimationSelectedSegment) {
    this.selected = decimationSelectedSegment.selected;
    this.segment = decimationSelectedSegment.segment;
  }
}

export class DecimationChartSeriesResponse implements IDecimationChartSeriesResponse {
  name;
  data: Array<IDecimationChartDataResponse>;

  constructor() {
    this.name = "";
    this.data = [];
  }

  clone(decimationChartSeriesResponse: DecimationChartSeriesResponse) {
    this.name = decimationChartSeriesResponse.name;
    this.data = decimationChartSeriesResponse.data;
  }
}

export class DecimationChartDataResponse implements IDecimationChartDataResponse {
  x;
  y;
  constructor() {
    this.x = 0;
    this.y = 0;
  }

  clone(decimationChartDataResponse: DecimationChartDataResponse) {
    this.x = decimationChartDataResponse.x;
    this.y = decimationChartDataResponse.y;
  }
}
