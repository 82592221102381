import { GridValidRowModel, GridColDef } from "@mui/x-data-grid-premium";
import { StyledDataGridPremium } from "../../../../app/component/StyledDataGridPremium";
import { FormikValues } from "formik";

type TStep1GridReference = {
  values: FormikValues;
  setFieldValue: any;
};

export default function Step1GridReference({ values, setFieldValue }: TStep1GridReference) {
  const handleRowEdit = (row: any) => {
    return row;
  };

  const gridColumns: GridColDef<GridValidRowModel>[] = [
    {
      field: "depth",
      headerName: "Depth (m)",
      align: "center",
      headerAlign: "center",
      flex: 1,
    },
    {
      field: "elevation",
      headerName: "Elevation (m asl)",
      align: "center",
      headerAlign: "center",
      flex: 1,
    },
    {
      field: "pressure",
      headerName: "Press (bar g)",
      align: "center",
      headerAlign: "center",
      flex: 1,
    },
    {
      field: "temperature",
      align: "center",
      headerName: "Temp (deg c)",
      headerAlign: "center",
      flex: 1,
    },
  ];

  return (
    <StyledDataGridPremium
      experimentalFeatures={{ columnGrouping: true }}
      disableRowSelectionOnClick
      getRowId={(row) => row.sequence}
      processRowUpdate={handleRowEdit}
      columns={gridColumns}
      pagination
      columnGroupingModel={[
        {
          groupId: "Reference",
          headerAlign: "center",
          children: [{ field: "rDepth" }, { field: "rElevation" }, { field: "rPress" }, { field: "rTemp" }],
        },
      ]}
      rows={values.step1?.correctionReferences}
      sx={{
        ".datagrid-disable": { background: "#EDEFF1" },
      }}
      getCellClassName={() => "datagrid-disable"}
      autoHeight
    />
  );
}
